/**
 * Component short description
 *
 * @see FaqList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  BulkDeleteButton,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FaqList.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FaqList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      pagination={null}
      exporter={false}
      title={t("ra.faq.title")}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField
          source="question.ro"
          label={t("ra.faq.question")}
          sortable={false}
        />
        <TextField
          source="answer.ro"
          label={t("ra.faq.answer")}
          sortable={false}
        />
        <EditButton basePath="/faq" />
      </Datagrid>
    </List>
  );
};

FaqList.propTypes = propTypes;
FaqList.defaultProps = defaultProps;

export default FaqList;
export { propTypes as FaqListPropTypes, defaultProps as FaqListDefaultProps };
