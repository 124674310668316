/**
 * Component short description
 *
 * @see ConditionalReferenceField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { TextField, ReferenceField } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ConditionalReferenceField.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const ConditionalReferenceField = (props) => {
  const { source, record, referenceSource, fallbackSource, ...rest } = props;

  const isValid = Boolean(record[source]);
  console.log(isValid);
  return isValid ? (
    <ReferenceField source={source} record={record} {...rest}>
      <TextField source={referenceSource} />
    </ReferenceField>
  ) : (
    <TextField source={fallbackSource} record={record} />
  );
};

ConditionalReferenceField.propTypes = propTypes;
ConditionalReferenceField.defaultProps = defaultProps;

export default ConditionalReferenceField;
export {
  propTypes as ConditionalReferenceFieldPropTypes,
  defaultProps as ConditionalReferenceFieldDefaultProps,
};
