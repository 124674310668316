export const LANGUAGES = [
  'ro',
  'hu',
  'de',
  'en',
];

const EXCEPTIONAL_FIELDS = [
  'name',
  'alternateName',
];

export function getRecordFieldLength(source, record, field, language) {
  if (!source.includes(field) || !record || !record[field] || !Object.keys(record).length) {
    return 0;
  }

  if (record[field]) {
    return language ? record[field][language].length : record[field].length;
  }

  const [imageKey] = source.split('.');
  if (!imageKey) {
    return 0;
  }

  return language ? record[imageKey][field][language].length : record[imageKey][field].length;
}

export function getRecordFieldByLanguages(source, record) {
  const newCharactersLengths = { ro: 0, hu: 0, de: 0, en: 0 };
  LANGUAGES.forEach((language) => {
    EXCEPTIONAL_FIELDS.forEach((field) => {
      if (source.includes(field)) {
        newCharactersLengths[language] = getRecordFieldLength(source, record, field, language);
      }
    })

    if (record[source] && record[source][language]) {
      newCharactersLengths[language] = record[source][language].length;
    }
  })

  return newCharactersLengths;
}
