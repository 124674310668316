/**
 * Component short description
 *
 * @see ReactAdmin.md for details
 */

/**
 * Imports React and third party packagess
 */
import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Redirect } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import { useQuery, useRequests } from "../../hooks";
import DestinationEdit from "../_destinations/DestinationEdit";
import DestinationList from "../_destinations/DestinationList";
import DestinationShow from "../_destinations/DestinationShow";
import FaqList from "../_faq/FaqList";
import Layout from "../Layout";
import FaqCreate from "../_faq/FaqCreate";
import FaqEdit from "../_faq/FaqEdit";
import FaqShow from "../_faq/FaqShow/FaqShow";
import PartnerCreate from "../_partners/PartnerCreate/PartnerCreate";
import PartnerShow from "../_partners/PartnerShow/PartnerShow";
import PartnerList from "../_partners/PartnerList/PartnerList";
import PartnerEdit from "../_partners/PartnerEdit/PartnerEdit";
import AttractionList from "../_attractions/AttractionList/AttractionList";
import AttractionCreate from "../_attractions/AttractionCreate/AttractionCreate";
import AttractionEdit from "../_attractions/AttractionEdit/AttractionEdit";
import AttractionShow from "../_attractions/AttractionShow/AttractionShow";
import NewsList from "../_news/NewsList/NewsList";
import NewsCreate from "../_news/NewsCreate/NewsCreate";
import NewsEdit from "../_news/NewsEdit/NewsEdit";
import NewsShow from "../_news/NewsShow/NewsShow";
import EventList from "../_events/EventList/EventList";
import EventEdit from "../_events/EventEdit/EventEdit";
import EventCreate from "../_events/EventCreate/EventCreate";
import EventShow from "../_events/EventShow/EventShow";
import ProductList from "../_products/ProductList/ProductList";
import ProductEdit from "../_products/ProductEdit/ProductEdit";
import ProductCreate from "../_products/ProductCreate/ProductCreate";
import ProductShow from "../_products/ProductShow/ProductShow";
import AccommodationList from "../_accommodations/AccommodationList/AccommodationList";
import AccommodationEdit from "../_accommodations/AccommodationEdit/AccommodationEdit";
import AccommodationCreate from "../_accommodations/AccommodationCreate/AccommodationCreate";
import AccommodationShow from "../_accommodations/AccommodationShow/AccommodationShow";
import InfoList from "../_info/InfoList/InfoList";
import InfoEdit from "../_info/InfoEdit/InfoEdit";
import InfoShow from "../_info/InfoShow/InfoShow";
import BookingHistoryList from "../_bookingHistory/BookingHistoryList/BookingHistoryList";
import BookingHistoryShow from "../_bookingHistory/BookingHistoryShow/BookingHistoryShow";
import HostList from "../_hosts/HostList/HostList";
import HostEdit from "../_hosts/HostEdit/HostEdit";
import HostShow from "../_hosts/HostShow/HostShow";
import HostApprovalEdit from "../_hostApprovals/HostApprovalEdit/HostApprovalEdit";
import SellerList from "../_sellers/SellerList/SellerList";
import SellerEdit from "../_sellers/SellerEdit/SellerEdit";
import SellerShow from "../_sellers/SellerShow/SellerShow";
import OrderList from "../_orders/OrderList/OrderList";
import OrderEdit from "../_orders/OrderEdit/OrderEdit";
import OrderShow from "../_orders/OrderShow/OrderShow";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./ReactAdmin.data";
import dataProvider from "../../dataProvider";
import romanianMessages from "../../translations";
import authProvider from "../../authProvider";
/**
 * Imports Material UI components
 */
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PeopleIcon from "@material-ui/icons/People";
import ExploreIcon from "@material-ui/icons/Explore";
import MuseumIcon from "@material-ui/icons/Museum";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import EventIcon from "@material-ui/icons/Event";
import HotelIcon from "@material-ui/icons/Hotel";
import InfoIcon from "@material-ui/icons/Info";
import SingleBedIcon from "@material-ui/icons/SingleBed";
import GroupIcon from "@material-ui/icons/Group";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingList from "../_settings/SettingList/SettingList";
import SettingEdit from "../_settings/SettingEdit/SettingEdit";
import SettingShow from "../_settings/SettingShow/SettingShow";

const i18nProvider = polyglotI18nProvider(() => romanianMessages, "ro");

/**
 * Displays the component
 */
const ReactAdmin = (props) => {
  const { token, to } = useQuery();
  const { get } = useRequests();

  const fetchPermissions = async (token) => {
    const basePath = process.env.REACT_APP_BASEPATH;

    try {
      const permissions = await get(
        "account/profile",
        {},
        {
          Authorization: `Bearer ${token}`,
        }
      );

      localStorage.setItem("user", JSON.stringify(permissions));
      const newPath = to ? `${basePath}#${to}` : basePath;
      document.location.href = newPath;
    } catch (e) {
      console.error(e);
      localStorage.setItem("user", JSON.stringify({}));
      localStorage.removeItem("token");
      document.location.href = `${basePath}#/login`;
    }
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify({}));
      fetchPermissions(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Admin
      dataProvider={dataProvider(`${process.env.REACT_APP_API_ENDPOINT}admin`)}
      authProvider={authProvider}
      catchAll={() => <Redirect to="/" />}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      {(permissions) => [
        permissions.isSuperAdmin || permissions.isLocalAdmin ? (
          <Resource
            name="destinations"
            options={{ label: "Destinații" }}
            list={DestinationList}
            show={DestinationShow}
            edit={DestinationEdit}
            icon={ExploreIcon}
          />
        ) : (
          <Resource name="destinations" />
        ),
        permissions.isSuperAdmin && (
          <Resource
            name="partners"
            options={{ label: "Parteneri" }}
            list={PartnerList}
            show={PartnerShow}
            edit={PartnerEdit}
            create={PartnerCreate}
            icon={PeopleIcon}
          />
        ),
        (permissions.isSuperAdmin || permissions.isLocalAdmin) && (
          <Resource
            name="attractions"
            options={{ label: "Atracții" }}
            list={AttractionList}
            create={AttractionCreate}
            edit={AttractionEdit}
            show={AttractionShow}
            icon={MuseumIcon}
          />
        ),

        permissions.isSuperAdmin && (
          <Resource
            name="faq"
            options={{ label: "FAQ" }}
            list={FaqList}
            edit={FaqEdit}
            create={FaqCreate}
            show={FaqShow}
            icon={QuestionAnswerIcon}
          />
        ),
        (permissions.isSuperAdmin || permissions.isLocalAdmin) && (
          <Resource
            name="news"
            options={{ label: "Știri" }}
            list={NewsList}
            edit={NewsEdit}
            create={NewsCreate}
            show={NewsShow}
            icon={AnnouncementIcon}
          />
        ),
        (permissions.isSuperAdmin || permissions.isLocalAdmin) && (
          <Resource
            name="events"
            options={{ label: "Evenimente" }}
            list={EventList}
            edit={EventEdit}
            create={EventCreate}
            show={EventShow}
            icon={EventIcon}
          />
        ),
        permissions.isSuperAdmin ||
        permissions.isLocalAdmin ||
        (permissions.isSeller && permissions.isSellerApproved) ? (
          <Resource
            name="market/products"
            options={{ label: "Produse" }}
            list={ProductList}
            edit={
              permissions.isSeller && permissions.isSellerApproved
                ? ProductEdit
                : null
            }
            create={
              permissions.isSeller && permissions.isSellerApproved
                ? ProductCreate
                : null
            }
            show={ProductShow}
          />
        ) : (
          <Resource name="market/products" />
        ),
        permissions.isSuperAdmin ||
        permissions.isLocalAdmin ||
        (permissions.isHost && permissions.hasApprovedHostDestinations) ? (
          <Resource
            name="stay/accommodations"
            options={{ label: "Cazări" }}
            list={AccommodationList}
            edit={permissions.isHost ? AccommodationEdit : null}
            create={permissions.isHost ? AccommodationCreate : null}
            show={AccommodationShow}
            icon={HotelIcon}
          />
        ) : (
          <Resource name="stay/accommodations" />
        ),

        (permissions.isSuperAdmin ||
          permissions.isLocalAdmin ||
          permissions.isHost) && (
          <Resource
            name="stay/bookings"
            options={{ label: "Istoric rezervări" }}
            list={BookingHistoryList}
            show={BookingHistoryShow}
            icon={SingleBedIcon}
          />
        ),
        permissions.isSuperAdmin && (
          <Resource
            name="info"
            options={{ label: "Info" }}
            list={InfoList}
            edit={InfoEdit}
            show={InfoShow}
            icon={InfoIcon}
          />
        ),
        (permissions.isSuperAdmin ||
          permissions.isLocalAdmin ||
          permissions.isHost) && (
          <Resource
            name="stay/hosts"
            options={{ label: "Gazde" }}
            list={HostList}
            edit={HostEdit}
            show={HostShow}
            icon={GroupIcon}
          />
        ),
        (permissions.isSuperAdmin || permissions.isLocalAdmin) && (
          <Resource
            name="stay/host-approvals"
            options={{ label: "Aprobări gazde" }}
            edit={HostApprovalEdit}
          />
        ),
        (permissions.isSuperAdmin ||
          permissions.isLocalAdmin ||
          permissions.isSeller) && (
          <Resource
            name="market/sellers"
            options={{ label: "Vânzători" }}
            list={SellerList}
            edit={SellerEdit}
            show={SellerShow}
            icon={SupervisorAccountIcon}
          />
        ),
        (permissions.isSuperAdmin ||
          permissions.isLocalAdmin ||
          permissions.isSeller) && (
          <Resource
            name="market/orders"
            options={{ label: "Comenzi" }}
            list={OrderList}
            edit={permissions.isSeller ? OrderEdit : null}
            show={OrderShow}
            icon={LocalShippingIcon}
          />
        ),

        permissions.isSuperAdmin && (
          <Resource
            name="settings"
            options={{ label: "Setări" }}
            list={SettingList}
            edit={SettingEdit}
            show={SettingShow}
            icon={SettingsIcon}
          />
        ),

        !permissions.isSuperAdmin && !permissions.isLocalAdmin && (
          <Resource name="destinations" />
        ),

        <Resource name="categories" />,
        <Resource name="market/categories" />,
        <Resource name="stay/accommodation-types" />,
        <Resource name="stay/room-types" />,
        <Resource name="stay/meals" />,
        <Resource name="stay/extras" />,
        <Resource name="market/units" />,
      ]}
    </Admin>
  );
};

ReactAdmin.propTypes = propTypes;
ReactAdmin.defaultProps = defaultProps;

export default ReactAdmin;
export {
  propTypes as ReactAdminPropTypes,
  defaultProps as ReactAdminDefaultProps,
};
