/**
 * Component short description
 *
 * @see NewsEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import { transformNews } from "../NewsCreate/NewsCreate";
import NewsForm from "../NewsForm/NewsForm";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./NewsEdit.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const NewsEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit
      {...props}
      undoable={false}
      title={t("ra.news.edit_title")}
      transform={transformNews}
    >
      <NewsForm />
    </Edit>
  );
};

NewsEdit.propTypes = propTypes;
NewsEdit.defaultProps = defaultProps;

export default NewsEdit;
export { propTypes as NewsEditPropTypes, defaultProps as NewsEditDefaultProps };
