/**
 * Component short description
 *
 * @see Layout.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useRef, useState } from "react";

import { Layout as RLayout } from "react-admin";

/**
 * Imports other components and hooks
 */
import Sidebar from "../Sidebar/Sidebar";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Layout.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  layout: {
    "& [class^='RaLayout-content']": { maxWidth: "100%" },
  },
}));

/**
 * Displays the component
 */
const Layout = (props) => {
  const { layout } = useStyles(props);
  const [hasNavigation, setHasNavigation] = useState(true);
  const initialWindowHeight = useRef(window.innerHeight);
  useEffect(() => {
    const windowListener = () => {
      if (window.innerHeight > initialWindowHeight.current) {
        setHasNavigation(false);
      } else {
        setHasNavigation(true);
      }
    };
    window.addEventListener("resize", windowListener);

    return () => window.removeEventListener("resize", windowListener);
  }, []);
  return (
    <div className={layout}>
      <RLayout
        {...props}
        sidebar={(props) => (
          <Sidebar {...props} hasNavigation={hasNavigation} />
        )}
        className={layout}
      />
    </div>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
export { propTypes as LayoutPropTypes, defaultProps as LayoutDefaultProps };
