/**
 * Component short description
 *
 * @see useValidation.md for details
 */

/**
 * Imports React and third party packages
 */
import { required, minLength, maxLength, number, email } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useValidation.data";

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const validUrl = (message = "ra.validation.invalidUrl") => (value) => {
  if (!value) {
    return undefined;
  }
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(value) ? undefined : message;
};

const maxRows = (
  rows,
  message = { message: "ra.validation.maxRows", args: { max: rows } }
) => (value) => {
  if (!value) {
    return undefined;
  }

  return value.toString().split(/\r\n|\r|\n/).length > rows
    ? message
    : undefined;
};

const minRows = (
  rows,
  message = { message: "ra.validation.minRows", args: { min: rows } }
) => (value) => {
  if (!value) {
    return undefined;
  }
  return value.split(/\r\n|\r|\n/).length < rows ? message : undefined;
};

const validPhone = (message = "ra.validation.phone") => (value) => {
  const pattern = new RegExp(/^\d{6,10}$/);
  return pattern.test(value) ? undefined : message;
};

const maxLengthPerRow = (
  lineLength,
  message = { message: "ra.validation.maxLengthPerRow", args: { value: 250 } }
) => (value) => {
  if (!value) {
    return undefined;
  }

  const lines = value.split(/\n/);
  let validation = undefined;
  lines.forEach((line) => {
    if (line.length > lineLength) {
      validation = message;
    }
  });

  return validation;
};

const validatePositiveNumber = (value) => {
  return isNaN(value) || value < 0
    ? { message: "ra.validation.number" }
    : undefined;
};

const validateArrayLength = (items) => {
  return items.length < 3 ? 'ra.validation.min_items' : undefined;
}

const getDescriptionValidation = (min, max, isRequired = true) => {
  const validation = [min, max, minLength(min), maxLength(max)];
  if (isRequired) {
    validation.push(required())
  }

  return validation;
}

const handleValidateOptionalImage = (values) => {
  const errors = {};
  if (values.image1 && !values.image1.author) {
    errors.image1 = { author: 'ra.validation.required' };
  }

  errors.authorVisible = !!values.image1;

  return errors;
}

const validateLongDescription = [maxLength(1500)];
const validateDropdown = [required()];
const validateTextGeneral = [3, 128, required(), maxLength(128), minLength(3)];
const validateAlternateName = [3, 128, minLength(3), maxLength(128)];
const validateDescription = [128, 500, required(), minLength(128), maxLength(500)];
const validateShortDescription = [128, 250, required(), minLength(128), maxLength(250)];
const validateDescriptionWithLongMin = [
  250,
  500,
  required(),
  minLength(250),
  maxLength(500),
];
const validateFaq = [0, 500, required(), maxLength(500)];
const validateGps = [required(), number()];
const validateOpeningHours = [0, 250, maxRows(7), maxLength(250)];
const validatePrice = [0, 250, maxRows(3), maxLength(250)];
const validatePriceWithMin = [3, 250, ...validatePrice, minLength(3)];
const validateCarouselImages = [required()];
const validateCarouselImagesWithLength = [required(), validateArrayLength];
const validateLink = [validUrl()];
const validateVideoUrl = [required(), validUrl()];
const validateResolutions = [required()];
const validateDate = [required()];
const validateProductPrice = [required(), number()];
const validateAccommodationPrice = [required(), number()];
const validateEmail = [required(), email()];
const validateProductQuantity = [number()];
const validateIsPositiveNumber = [number(), validatePositiveNumber];
const validatePhone = [required(), validPhone()];

const validateSectionListing = [
  3,
  750,
  required(),
  maxRows(3),
  maxLength(750),
  minRows(3),
  maxLengthPerRow(250),
];

const validateSectionListingWithMin = [
  128,
  750,
  ...validateSectionListing,
  minLength(128),
];

/**
 * Displays the component
 */
const useValidation = () => {
  return {
    validateTextGeneral,
    validateDescription,
    validateGps,
    validateOpeningHours,
    validateCarouselImages,
    validateCarouselImagesWithLength,
    validatePrice,
    validatePriceWithMin,
    validateLink,
    validateSectionListing,
    validateDropdown,
    validateResolutions,
    validateLongDescription,
    validateFaq,
    validateAlternateName,
    validateIsPositiveNumber,
    validateDate,
    validateProductPrice,
    validateProductQuantity,
    validateVideoUrl,
    validateShortDescription,
    validateDescriptionWithLongMin,
    validateSectionListingWithMin,
    validateAccommodationPrice,
    validateEmail,
    validatePhone,
    getDescriptionValidation,
    handleValidateOptionalImage,
  };
};

useValidation.propTypes = propTypes;
useValidation.defaultProps = defaultProps;

export default useValidation;
export {
  composeValidators,
  validUrl,
  maxRows,
  propTypes as useValidationPropTypes,
  defaultProps as useValidationDefaultProps,
};
