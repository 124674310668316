/**
 * Component short description
 *
 * @see AccommodationCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationCreate.data";
import { parseImage } from "../../ReactAdmin/ReactAdmin.logic";
import AccommodationForm from "../AccommodationForm/AccommodationForm";

const transformAccommodation = (data) => {
  const images = data?.images?.map((image) => {
    return parseImage(image);
  });

  return {
    ...data,
    images,
  };
};

/**
 * Displays the component
 */
const AccommodationCreate = (props) => {
  const t = useTranslate();

  return (
    <Create
      {...props}
      title={t("ra.accommodation.create_title")}
      transform={transformAccommodation}
    >
      <AccommodationForm />
    </Create>
  );
};
AccommodationCreate.propTypes = propTypes;
AccommodationCreate.defaultProps = defaultProps;

export default AccommodationCreate;
export {
  transformAccommodation,
  propTypes as AccommodationCreatePropTypes,
  defaultProps as AccommodationCreateDefaultProps,
};
