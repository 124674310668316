/**
 * Component short description
 *
 * @see HostForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  TextInput,
  SimpleForm,
  ArrayField,
  Datagrid,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import { useValidation } from "../../../hooks";
import StatusField from "../../StatusField/StatusField";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HostForm.data";
import ToolbarWithoutDelete from "../../ToolbarWithoutDelete/ToolbarWithoutDelete";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HostForm = (props) => {
  const { permissions } = props;
  const t = useTranslate();
  const { validateTextGeneral, validateEmail, validatePhone } = useValidation();
  return (
    <SimpleForm
      {...props}
      variant="outlined"
      redirect="show"
      toolbar={<ToolbarWithoutDelete />}
    >
      {permissions?.isHost ? (
        <TextInputWithCharacterCount
          fullWidth
          source="name"
          label="ra.host.name"
          validate={validateTextGeneral}
          disabled={!permissions?.isHost}
        />
      ) : null}
      {permissions?.isHost ? (
        <TextInput
          fullWidth
          source="email"
          label="ra.host.email"
          validate={validateEmail}
        />
      ) : null}
      {permissions?.isHost ? (
        <TextInput
          fullWidth
          source="phone"
          label="ra.host.phone"
          validate={validatePhone}
          disabled={!permissions?.isHost}
        />
      ) : null}
      <ArrayField label="ra.host.approvals" source="approvals">
        <Datagrid
          rowClick={(id, basepath, record) => {
            return permissions?.isLocalAdmin || permissions?.isSuperAdmin
              ? `/stay/host-approvals/${record.id}`
              : null;
          }}
        >
          <TextField source="id" sortable={false} />
          <ReferenceField
            reference="destinations"
            source="destinationId"
            label={t("ra.host.destination")}
            sortable={false}
            link="show"
          >
            <TextField source="name.ro" sortable={false} />
          </ReferenceField>
          <StatusField
            source="status"
            label={t("ra.host.status")}
            sortable={false}
          />
          <TextField
            source="reason"
            label={t("ra.host.reason")}
            sortable={false}
          />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  );
};

HostForm.propTypes = propTypes;
HostForm.defaultProps = defaultProps;

export default HostForm;
export { propTypes as HostFormPropTypes, defaultProps as HostFormDefaultProps };
