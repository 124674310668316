/**
 * Component short description
 *
 * @see HostApprovalEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import HostApprovalForm from "../HostApprovalForm/HostApprovalForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HostApprovalEdit.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HostApprovalEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit {...props} undoable={false} title={t("ra.faq.edit_title")}>
      <HostApprovalForm />
    </Edit>
  );
};

HostApprovalEdit.propTypes = propTypes;
HostApprovalEdit.defaultProps = defaultProps;

export default HostApprovalEdit;
export {
  propTypes as HostApprovalEditPropTypes,
  defaultProps as HostApprovalEditDefaultProps,
};
