/**
 * Component short description
 *
 * @see useRequests.md for details
 */

/**
 * Imports React and third party packages
 */
import queryString from "query-string";
import { showNotification } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useRequests.data";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const checkResponse = (res) => {
  if (res.status === 200 || res.status === 201) {
    return res.json();
  } else {
    throw res;
  }
};

const useRequests = () => {
  const handleNetworkError = () => {
    showNotification("A apărut o eroare. Vă rugăm încercați din nou.", "error");
  };

  const get = async (path, params = {}, customHeaders = {}) => {
    const queryParams = queryString.stringify(params);
    const res = await fetch(`${apiUrl}${path}?${queryParams}`, {
      headers: {
        ...customHeaders,
      },
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  const post = async (path, body, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...customHeaders,
      },
      body: JSON.stringify(body),
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  const put = async (path, body = {}, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  const deleteReq = async (path, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", ...customHeaders },
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  return { get, post, put, deleteReq };
};

useRequests.propTypes = propTypes;
useRequests.defaultProps = defaultProps;

export default useRequests;
export { useRequests };
