/**
 * Defines the data requirements for the component
 *
 * @see BooleanField.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
};

/**
 * Defines the default props
 */
const defaultProps = {
  addLabel: true,
};

export { propTypes, defaultProps };
