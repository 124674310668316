/**
 * Component short description
 *
 * @see BackButton.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Button } from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./BackButton.data";

/**
 * Imports Material UI components
 */
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-admin";

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const BackButton = (props) => {
  const history = useHistory();
  const t = useTranslate();

  return (
    <Button
      onClick={history.goBack}
      variant="contained"
      size="medium"
      label={t("ra.general.back")}
      title={t("ra.general.back")}
      style={{ marginLeft: 16 }}
      {...props}
    ></Button>
  );
};

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;
export {
  propTypes as BackButtonPropTypes,
  defaultProps as BackButtonDefaultProps,
};
