import { useEffect, useState } from "react";
import useTabsValidation from "./useValidation/useTabsValidation";
import { useValidation } from "./index";

const useFormValidation = () => {
  const { handleValidateOptionalImage } = useValidation();

  const [toggleChange, setToggleChange] = useState(false);
  const [authorIsVisible, setAuthorIsVisible] = useState(true);
  const { setTabsFormErrors } = useTabsValidation();

  useEffect(() => {
    setTabsFormErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleChange]);

  const handleValidate = () => {
    setToggleChange(!toggleChange);
  };

  const handleImageValidate = (values) => {
    const { authorVisible, ...errors } = handleValidateOptionalImage(values);
    setAuthorIsVisible(authorVisible);

    return errors;
  }

  return {
    authorIsVisible,
    handleValidate,
    handleImageValidate
  }
}

export default useFormValidation;
