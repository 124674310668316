/**
 * Component short description
 *
 * @see PartnerShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  ImageField,
  useTranslate,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./PartnerShow.data";
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const PartnerShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.partner.show_title")}>
      <SimpleShowLayout varinat="outlined">
        <MultilanguageTextField source="name" label={t("ra.partner.name")} />

        <MultilanguageTextField
          source="logo.alternateName"
          label={t("ra.partner.logo_alternate_name")}
        />

        <TextField source="logo.author" label={t("ra.general.author")} />

        <ImageField
          source="logo.resolutions[0].path"
          label={t("ra.partner.logo")}
        />
        <UrlField source="url" label={t("ra.general.url")} />
      </SimpleShowLayout>
    </Show>
  );
};

PartnerShow.propTypes = propTypes;
PartnerShow.defaultProps = defaultProps;

export default PartnerShow;
export {
  propTypes as PartnerShowPropTypes,
  defaultProps as PartnerShowDefaultProps,
};
