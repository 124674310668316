/**
 * Component short description
 *
 * @see FaqForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { SimpleForm, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import { useValidation } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FaqForm.data";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const FaqForm = (props) => {
  const t = useTranslate();
  const { validateFaq } = useValidation();
  return (
    <SimpleForm {...props} variant="outlined" redirect="show">
      <MultilanguageTextInput
        fullWidth
        source="question"
        multiline
        rowsMax={4}
        label={t("ra.faq.question")}
        validate={validateFaq}
      />
      <MultilanguageTextInput
        fullWidth
        source="answer"
        multiline
        rowsMax={4}
        label={t("ra.faq.answer")}
        validate={validateFaq}
      />
    </SimpleForm>
  );
};

FaqForm.propTypes = propTypes;
FaqForm.defaultProps = defaultProps;

export default FaqForm;
export { propTypes as FaqFormPropTypes, defaultProps as FaqFormDefaultProps };
