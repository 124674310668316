/**
 * Component short description
 *
 * @see BookingHistoryShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
  ReferenceField,
  EmailField,
  ImageField,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import ConditionalReferenceField from "../../ConditionalReferenceField/ConditionalReferenceField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./BookingHistoryShow.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const BookingHistoryShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.booking_history.show_title")}>
      <SimpleShowLayout>
        <ConditionalReferenceField
          reference="stay/accommodations"
          source="accommodationId"
          label={t("ra.booking_history.accommodation")}
          sortable={false}
          link="show"
          referenceSource="name.ro"
          fallbackSource="accommodationName.ro"
        />
        <ReferenceField
          reference="destinations"
          source="destinationId"
          label={t("ra.booking_history.destination")}
          link="show"
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <TextField
          source="userName"
          label={t("ra.booking_history.username")}
          sortable={false}
        />
        <TextField
          source="userPhone"
          label={t("ra.booking_history.phone")}
          sortable={false}
        />
        <EmailField source="userEmail" label={t("ra.booking_history.email")} />

        <ReferenceField
          reference="stay/hosts"
          source="hostId"
          label={t("ra.booking_history.hostname")}
          sortable={false}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>

        <TextField
          source="hostPhone"
          label={t("ra.booking_history.host_phone")}
          sortable={false}
        />
        <EmailField
          source="hostEmail"
          label={t("ra.booking_history.host_email")}
        />

        <TextField
          source="price"
          label={t("ra.booking_history.price")}
          sortable={false}
        />

        <TextField
          source="rooms"
          label={t("ra.booking_history.rooms")}
          sortable={false}
        />
        <TextField
          source="guests"
          label={t("ra.booking_history.guests")}
          sortable={false}
        />
        <TextField
          source="checkIn"
          label={t("ra.booking_history.check_in")}
          sortable={false}
        />
        <TextField
          source="checkOut"
          label={t("ra.booking_history.check_out")}
          sortable={false}
        />
        <TextField
          source="message"
          label={t("ra.booking_history.message")}
          sortable={false}
        />
        <ImageField
          source="image.resolutions[0].path"
          label={t("ra.general.image")}
        />
      </SimpleShowLayout>
    </Show>
  );
};

BookingHistoryShow.propTypes = propTypes;
BookingHistoryShow.defaultProps = defaultProps;

export default BookingHistoryShow;
export {
  propTypes as BookingHistoryShowPropTypes,
  defaultProps as BookingHistoryShowDefaultProps,
};
