/**
 * Component short description
 *
 * @see MultiLanguageLinkField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Labeled, UrlField } from "react-admin";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./MultiLanguageLinkField.data";
/**
 * Imports Material UI components
 */
import { Box } from "@material-ui/core";

const MultiLanguageLinkField = props => {
  const { record, source, label } = props;

  return (
    <Box className="ra-field" display="flex" flexDirection="column">
      {
        Object.keys(record[source]).map(key => {
          return (
            <Labeled label={`${label} (${key.toUpperCase()})`}>
              <UrlField
                {...props}
                source={`${source}.${key}`}
                label={label}
              />
            </Labeled>
          );
        })
      }
    </Box>
  );
};

MultiLanguageLinkField.propTypes = propTypes;
MultiLanguageLinkField.defaultProps = defaultProps;

export default MultiLanguageLinkField;
export {
  propTypes as MultiLanguageLinkFieldPropTypes,
  defaultProps as MultiLanguageLinkFieldDefaultProps
};
