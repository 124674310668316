/**
 * Component short description
 *
 * @see InfoForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  SimpleForm,
  useTranslate,
  FormDataConsumer,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./InfoForm.data";

/**
 * Imports Material UI components
 */
import { useValidation } from "../../../hooks";
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import EditableImageField from "../../EditableImageField";
import ToolbarWithoutDelete from "../../ToolbarWithoutDelete/ToolbarWithoutDelete";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const InfoForm = (props) => {
  const {
    validateDescription,
    validateTextGeneral,
    validateResolutions,
    validateAlternateName,
  } = useValidation();
  const t = useTranslate();
  return (
    <SimpleForm
      variant="outlined"
      {...props}
      toolbar={<ToolbarWithoutDelete />}
      redirect="show"
    >
      <MultilanguageTextInput
        source="text"
        label={t("ra.info.description")}
        fullWidth
        validate={validateDescription}
      />
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData.isImageRequired ? (
            <MultilanguageTextInput
              source="image.alternateName"
              fullWidth
              label={t("ra.general.image_alternate_name")}
              validate={validateAlternateName}
              {...rest}
            />
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData.isImageRequired ? (
            <TextInputWithCharacterCount
              source="image.author"
              label={t("ra.general.author")}
              fullWidth
              validate={validateTextGeneral}
              {...rest}
            />
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.isImageRequired ? (
            <EditableImageField
              source="image.resolutions"
              label={t("ra.partner.logo")}
              validate={validateResolutions}
              {...rest}
            />
          ) : null
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};

InfoForm.propTypes = propTypes;
InfoForm.defaultProps = defaultProps;

export default InfoForm;
export { propTypes as InfoFormPropTypes, defaultProps as InfoFormDefaultProps };
