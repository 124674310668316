/**
 * Component short description
 *
 * @see EventShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ImageField,
  TabbedShowLayoutTabs,
  ReferenceField,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventShow.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const EventShow = (props) => {
  const t = useTranslate();

  return (
    <Show {...props} title={t("ra.event.show_title")}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab label={t("ra.event.details")}>
          <ReferenceField
            reference="destinations"
            source="destinationId"
            link="show"
            label={t("ra.event.destination")}
          >
            <TextField source="name.ro" />
          </ReferenceField>

          <MultilanguageTextField source="name" label={t("ra.event.name")} />
          <DateField source="startDate" label={t("ra.event.start_date")} />
          <DateField source="endDate" label={t("ra.event.end_date")} />

          <MultilanguageTextField
            source="description"
            label={t("ra.event.description")}
          />
          <MultilanguageTextField
            source="image.alternateName"
            label={t("ra.event.main_image_alternate_name")}
          />
          <TextField source="image.author" label={t("ra.general.author")} />
          <ImageField
            source="image.resolutions[0].path"
            label={t("ra.event.main_image")}
          />
        </Tab>
        <Tab label={t("ra.general.general_description")}>
          <MultilanguageTextField
            source="sectionDescription1"
            label={t("ra.general.description")}
          />
          <MultilanguageTextField
            source="image1.alternateName"
            label={t("ra.general.image_alternate_name")}
          />
          <TextField source="image1.author" label={t("ra.general.author")} />
          <ImageField
            source="image1.resolutions[0].path"
            label={t("ra.general.image")}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

EventShow.propTypes = propTypes;
EventShow.defaultProps = defaultProps;

export default EventShow;
export {
  propTypes as EventShowPropTypes,
  defaultProps as EventShowDefaultProps,
};
