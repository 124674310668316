/**
 * Component short description
 *
 * @see OrderList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  DateField,
  NumberField,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderList.data";
import StatusField from "../../StatusField/StatusField";
import ConditionalReferenceField from "../../ConditionalReferenceField/ConditionalReferenceField";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const OrderList = (props) => {
  const t = useTranslate();
  const { permissions } = props;

  return (
    <List
      {...props}
      exporter={false}
      title={t("ra.order.title")}
      bulkActionButtons={false}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" sortable={false} />
        <ConditionalReferenceField
          reference="market/sellers"
          source="sellerId"
          label={t("ra.order.seller")}
          link="show"
          sortable={false}
          referenceSource="name"
          fallbackSource="sellerName"
        />
        <TextField
          source="deliveryName"
          label={t("ra.order.delivery_name")}
          sortable={false}
        />
        <TextField
          source="deliveryAddress"
          label={t("ra.order.delivery_address")}
          sortable={false}
        />
        <TextField
          source="deliveryPhone"
          label={t("ra.order.delivery_phone")}
          sortable={false}
        />
        <NumberField
          source="totalPrice"
          label="ra.order.total_price"
          sortable={false}
        />
        <DateField
          source="orderDate"
          label="ra.order.order_date"
          sortable={false}
        />

        <StatusField source="status" label="ra.order.status" sortable={false} />

        {permissions?.isSeller && <EditButton basePath="/market/orders" />}
      </Datagrid>
    </List>
  );
};

OrderList.propTypes = propTypes;
OrderList.defaultProps = defaultProps;

export default OrderList;
export {
  propTypes as OrderListPropTypes,
  defaultProps as OrderListDefaultProps,
};
