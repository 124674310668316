/**
 * Component short description
 *
 * @see SellerShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  SimpleShowLayout,
  useTranslate,
  TextField,
  EmailField,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SellerShow.data";
import StatusField from "../../StatusField/StatusField";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const SellerShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.seller.show_title")}>
      <SimpleShowLayout>
        <TextField
          source="destinationName"
          label={t("ra.seller.destination")}
          sortable={false}
        />
        <TextField source="name" label={t("ra.seller.name")} sortable={false} />

        <EmailField
          source="email"
          label={t("ra.seller.email")}
          sortable={false}
        />
        <TextField
          source="phone"
          label={t("ra.seller.phone")}
          sortable={false}
        />
        <StatusField source="approvalStatus" label={t("ra.host.status")} />
        <TextField
          source="message"
          label={t("ra.seller.message")}
          sortable={false}
        />
        <TextField
          source="sellerPaypal"
          label={t("ra.seller.paypal")}
          sortable={false}
        />
      </SimpleShowLayout>
    </Show>
  );
};

SellerShow.propTypes = propTypes;
SellerShow.defaultProps = defaultProps;

export default SellerShow;
export {
  propTypes as SellerShowPropTypes,
  defaultProps as SellerShowDefaultProps,
};
