/**
 * Component short description
 *
 * @see OrderEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderEdit.data";
import OrderForm from "../OrderForm/OrderForm";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const OrderEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit {...props} undoable={false} title={t("ra.order.edit_title")}>
      <OrderForm permissions={props.permissions} />
    </Edit>
  );
};

OrderEdit.propTypes = propTypes;
OrderEdit.defaultProps = defaultProps;

export default OrderEdit;
export {
  propTypes as OrderEditPropTypes,
  defaultProps as OrderEditDefaultProps,
};
