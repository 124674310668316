/**
 * Component short description
 *
 * @see AccommodationShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  TextField,
  ImageField,
  useTranslate,
  TabbedShowLayout,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TabbedShowLayoutTabs,
  Tab,
  NumberField,
  ArrayField,
  Datagrid,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationShow.data";
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const AccommodationShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.accommodation.show_title")}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab label={t("ra.accommodation.details")}>
          <ReferenceField
            reference="destinations"
            source="destinationId"
            label={t("ra.accommodation.destination")}
            link="show"
          >
            <TextField source="name.ro" />
          </ReferenceField>
          <ReferenceField
            reference="stay/accommodation-types"
            source="accommodationTypeId"
            label="ra.accommodation.type"
            link={null}
          >
            <TextField source="name.ro" />
          </ReferenceField>
          <ReferenceField
            reference="stay/hosts"
            source="hostId"
            label={t("ra.accommodation.host")}
            sortable={false}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceArrayField
            label="ra.accommodation.room_type"
            reference="stay/room-types"
            source="accommodationRoomTypeIds"
          >
            <SingleFieldList linkType={null}>
              <ChipField source="name.ro" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField
            label="ra.accommodation.meal_type"
            source="accommodationMealIds"
            reference="stay/meals"
            link={null}
          >
            <SingleFieldList linkType={null}>
              <ChipField source="name.ro" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField
            label="ra.accommodation.extras"
            source="accommodationExtrasIds"
            reference="stay/extras"
            link={null}
          >
            <SingleFieldList linkType={null}>
              <ChipField source="name.ro" />
            </SingleFieldList>
          </ReferenceArrayField>

          <MultilanguageTextField
            source="name"
            label={t("ra.accommodation.name")}
          />

          <MultilanguageTextField
            source="description"
            label={t("ra.accommodation.description")}
          />

          <NumberField source="price" label={t("ra.accommodation.price")} />

          <NumberField source="latitude" label={t("ra.general.latitude")} />

          <NumberField s source="longitude" label={t("ra.general.longitude")} />
        </Tab>
        <Tab label={t("ra.accommodation.images")}>
          <ArrayField source="images">
            <Datagrid>
              <MultilanguageTextField
                source="alternateName"
                label={t("ra.general.image_alternate_name")}
                sortable={false}
              />

              <TextField
                source="author"
                label={t("ra.general.author")}
                sortable={false}
              />
              <ImageField
                source="resolutions[0].path"
                label={t("ra.general.image")}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={t("ra.general.general_description")}>
          <MultilanguageTextField
            source="sectionDescription1"
            label={t("ra.general.description")}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

AccommodationShow.propTypes = propTypes;
AccommodationShow.defaultProps = defaultProps;

export default AccommodationShow;
export {
  propTypes as AccommodationShowPropTypes,
  defaultProps as AccommodationShowDefaultProps,
};
