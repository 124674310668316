/**
 * Component short description
 *
 * @see InfoShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  SimpleShowLayout,
  TextField,
  ShowController,
  ShowView,
  ImageField,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./InfoShow.data";
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const InfoShow = (props) => {
  const t = useTranslate();
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView
          {...props}
          {...controllerProps}
          title={t("ra.info.show_title")}
        >
          <SimpleShowLayout varinat="outlined">
            <MultilanguageTextField
              source="text"
              label={t("ra.info.description")}
            />

            {controllerProps?.record?.isImageRequired && (
              <MultilanguageTextField
                source="image.alternateName"
                label={t("ra.general.image_alternate_name")}
              />
            )}

            {controllerProps?.record?.isImageRequired && (
              <TextField source="image.author" label={t("ra.general.author")} />
            )}

            {controllerProps?.record?.isImageRequired && (
              <ImageField
                source="image.resolutions[0].path"
                label={t("ra.general.image")}
              />
            )}
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

InfoShow.propTypes = propTypes;
InfoShow.defaultProps = defaultProps;

export default InfoShow;
export { propTypes as InfoShowPropTypes, defaultProps as InfoShowDefaultProps };
