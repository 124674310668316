const romanianMessages = {
  ra: {
    action: {
      add_filter: "Adaugă filtru",
      add: "Adăugă",
      back: "Înapoi",
      bulk_actions: "1 articol selectat |||| %{smart_count} articole selectate",
      cancel: "Anulează",
      clear_input_value: "Șterge valoarea",
      clone: "Copiază",
      confirm: "Confirmă",
      create: "Crează",
      delete: "Șterge",
      edit: "Editează",
      export: "Exportă",
      list: "Listează",
      refresh: "Reincarcă",
      remove_filter: "Șterge acest filtru",
      remove: "Șterge",
      save: "Salvează",
      search: "Caută",
      show: "Arată",
      sort: "Sortează",
      undo: "Anulează",
      expand: "Extinde",
      close: "Închide",
      open_menu: "Deschide meniu",
      close_menu: "Închide meniu",
      unselect: "Deselectează",
    },
    boolean: {
      true: "Da",
      false: "Nu",
      null: "",
    },
    page: {
      create: "Crează %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "Ceva n-a mers bine",
      list: "%{name}",
      loading: "Încărcare",
      not_found: "Nu s-a găsit",
      show: "%{name} #%{id}",
      empty: "Nu sunt %{name} încă.",
      invite: "Vrei să adaugi?",
    },
    input: {
      file: {
        upload_several:
          "Trageți aici fișiere pentru a încărca sau clic pentru a selecta unul.",
        upload_single:
          "Trageți aici un fișier pentru a încărca sau clic pentru a selecta unul.",
      },
      image: {
        upload_several:
          "Trageți aici imagini pentru a încărca sau clic pentru a selecta una.",
        upload_single:
          "Trageți aici o imagin pentru a încărca sau clic pentru a selecta una.",
      },
      references: {
        all_missing: "Nu se pot găsi datele referențiate.",
        many_missing:
          "Cel puțin una dintre referințele asociate nu mai este disponibilă.",
        single_missing: "Referința asociată nu mai este disponibilă.",
      },
      password: {
        toggle_visible: "Ascunde parola",
        toggle_hidden: "Arată parola",
      },
    },
    message: {
      about: "Despre",
      are_you_sure: "Sunteți sigur?",
      bulk_delete_content:
        "Sigur doriți să ștergeți articolul %{name}? |||| Sigur doriți să ștergeți aceste %{smart_count} articole?",
      bulk_delete_title: "Șterge %{name} |||| Șterge %{smart_count} %{name}",
      delete_content: "Sigur doriți să ștergeți acest articol?",
      delete_title: "Șterge %{name} #%{id}",
      details: "Detalii",
      error: "A apărut o eroare și solicitarea dvs. nu a putut fi finalizată",
      invalid_form: "Formularul nu este valid. Verificați erorile.",
      loading: "Pagina se încarcă, doar un moment..",
      no: "Nu",
      not_found:
        "Fie că ați introdus o adresă URL greșită, fie ați urmat un link necorespunzător",
      yes: "Da",
      unsaved_changes:
        "Unele dintre modificările dvs. nu au fost salvate. Sunteți sigur că doriți să le ignorați?",
    },
    navigation: {
      no_results: "Nu au fost găsite rezultate",
      no_more_results:
        "Numărul de pagină %{page} este în afara limitelor. Încercați pagina anterioară.The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries:
        "PNumărul de pagină %{page} este în afara limitelor",
      page_out_from_end: "Nu se poate merge după ultima pagină",
      page_out_from_begin: "Nu se poate merge înainte de pagina 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} din %{total}",
      page_rows_per_page: "Rânduri pe pagină:",
      next: "Următorul",
      prev: "Precendetul",
    },
    auth: {
      auth_check_error: "Vă rugăm să vă autentificați pentru a continua",
      user_menu: "Profil",
      username: "Email",
      password: "Parolă",
      sign_in: "Conectare",
      sign_in_error: "Autentificarea a eșuat. Vă rugăm să încercați din nou.",
      logout: "Deconectare",
    },
    notification: {
      updated:
        "Articolul s-a actualizat |||| S-au actualizat %{smart_count} articole",
      created: "Articolul s-a creat",
      deleted: "Articolul s-a șters |||| S-au șters %{smart_count} articole",
      bad_item: "Articol incorect",
      item_doesnt_exist: "Articolul nu există",
      http_error: "Eroare de comunicare cu serverul",
      data_provider_error:
        "Eroare dataProvider. Verificați detaliile în consolă.",
      i18n_error: "Traducerile nu pot fi încărcate pentru limba specificată",
      canceled: "Acțiunea a fost anulată",
      logged_out: "Sesiunea dvs. s-a încheiat, vă rugăm să reconectați.",
    },
    destination: {
      title: "Destinații",
      edit_title: "Editare destinație",
      create_title: "Creare destinație",
      show_title: "Vizualizare destinație",
      name: "Numele destinației",
      slug: "Slug",
      pdf: "PDF",
      transportation: "Transportation",
      webcam: "Webcam",
      details: "Detalii",
      images: "Imagini carousel",
      alternate_name: "Nume alternativ destinație",
      description: "Descriere destinație",
      longDescription: "Descriere completa a destinației",
      video_url: "Link Youtube",
      video_name: "Nume",
      videos: "Videouri",
    },
    attraction: {
      create_title: "Creare atracție",
      edit_title: "Editare atracție",
      show_title: "Vizualizare atracție",
      title: "Atracții",
      details: "Detalii",
      name: "Numele atracției",
      destination: "Destinație",
      category: "Categorie",
      slug: "Slug",
      description: "Descrierea atracției",
      address: "Adresa",
      price: "Preț",
      opening_hours: "Orar",
      audio: "File audio",
      track: "File traseu",
      images: "Imagini carousel",
      section1: "Secțiune 1",
      section2: "Secțiune 2",
      section_title: "Titlul secțiunii",
      section_description: "Descrierea secțiunii",
      section_listing: "Listare secțiune",
      url: "Pagină web",
      facebook: "Pagina de Facebook",
      qr_code: "Cod QR",
      download_qr_code: "Descărcare cod QR",
      instagram: "Pagina de Instagram",
    },
    partner: {
      edit_title: "Editare partener",
      create_title: "Create partener",
      show_title: "Vizualizare partener",
      title: "Parteneri",
      name: "Nume partener",
      logo_alternate_name: "Nume alternativ logo",
      url: "Pagină web",
      logo: "Logo",
    },
    faq: {
      title: "FAQ",
      create_title: "Creare FAQ",
      edit_title: "Editare Gazdă",
      show_title: "Vizualizare FAQ",
      question: "Întrebare",
      answer: "Răspuns",
    },
    news: {
      title: "Știri",
      create_title: "Creare știre",
      edit_title: "Editare știre",
      show_title: "Vizualizare știre",
      slug: "Slug",
      destination: "Destinație",
      name: "Numele știrii",
      description: "Descrierea știrii",
      details: "Detalii",
      section1: "Secțiune 1",
      section2: "Secțiune 2",
      section3: "Secțiune 3",
      subsection1_description: "Descriere subsecțiune 1",
      subsection2_description: "Descriere subsecțiune 2",
      main_image_alternate_name: "Numele alternativ al imaginii principale",
      main_image: "Imaginea principală",
    },
    event: {
      title: "Evenimente",
      create_title: "Creare eveniment",
      edit_title: "Editare eveniment",
      show_title: "Vizualizare eveniment",
      slug: "Slug",
      destination: "Destinație",
      name: "Numele evenimentului",
      description: "Descrierea evenimentului",
      details: "Detalii",
      section1: "Secțiune 1",
      section2: "Secțiune 2",
      section3: "Secțiune 3",
      subsection1_description: "Descriere subsecțiune 1",
      subsection2_description: "Descriere subsecțiune 2",
      main_image_alternate_name: "Numele alternativ al imaginii principale",
      main_image: "Imaginea principală",
      start_date: "Data începerii",
      end_date: "Data terminării",
    },
    product: {
      title: "Produse",
      create_title: "Creare produs",
      edit_title: "Editare produs",
      show_title: "Vizualizare produs",
      destination: "Destinație",
      category: "Catetgoria produsului",
      name: "Numele produsului",
      description: "Descriere",
      price: "Preț",
      images: "Imagini",
      section_description_1: "Descrierea primei secțiuni",
      section_description_2: "Descrierea celei de-a doua secțiuni",
      details: "Detalii",
      sections: "Secțiuni",
      slug: "Slug",
      unit: "Unitate",
      min: "Numărul minim de produse",
      max: "Numărul maxim de produse",
      seller: "Vânzător",
    },
    accommodation: {
      title: "Cazări",
      create_title: "Creare cazare",
      edit_title: "Editare cazare",
      show_title: "Vizualizare cazare",
      destination: "Destinație",
      type: "Tipul cazării",
      room_type: "Tipul camerei",
      meal_type: "Tipul meselor",
      extras: "Extra",
      host: "Gazdă",
      images: "Imagini",
      details: "Detalii",
      sections: "Secțiuni",
      slug: "Slug",
      name: "Numele cazării",
      price: "Preț",
      description: "Descriere",
      latitude: "Latitudine",
      longitude: "Lonngitudine",
      section_description_1: "Descrierea primei secțiuni",
      section_description_2: "Descrierea celei de-a doua secțiuni",
    },
    info: {
      title: "Info",
      edit_title: "Editare info",
      show_title: "Vizualizare info",
      name: "Nume",
      description: "Descriere",
    },
    booking_history: {
      title: "Istoric rezevări",
      show_title: "Vizualizare rezervare",
      accommodation: "Numele cazării",
      destination: "Numele destinației",
      price: "Preț",
      check_in: "Check in",
      check_out: "Check out",
      rooms: "Număr camere",
      guests: "Număr musafiri",
      message: "Mesaj",
      username: "Nume utilizator",
      email: "Adresă email utilizator",
      phone: "Număr telefon utilizator",
      hostname: "Numele gazdei",
      host_email: "Adresă email gazdă",
      host_phone: "Număr telefon gazdă",
    },
    host: {
      title: "Gazde",
      show_title: "Vizualizare gazdă",
      edit_title: "Editare gazdă",
      name: "Nume",
      email: "Adresă email",
      phone: "Număr telefon",
      approvals: "Aprobări",
      destination: "Destinație",
      status: "Status",
      reason: "Motiv",
    },
    seller: {
      title: "Vânzători",
      show_title: "Vizualizare vânzător",
      edit_title: "Editare vânzător",
      status: "Status",
      destination: "Destinație",
      email: "Email",
      message: "Motiv",
      name: "Nume",
      phone: "Număr telefon",
      paypal: "ID cont PayPal",
    },
    order: {
      title: "Comenzi",
      show_title: "Vizualizare comandă",
      edit_title: "Editare comandă",
      cost: "Preț transport",
      status: "Status",
      refusal_reason: "Motivul respingerii",
      delivery_address: "Adresă livrare",
      delivery_name: "Nume cumpărător",
      delivery_phone: "Nr. telefon cumpărător",
      order_date: "Data comenzii",
      personal_address: "Adresă personală",
      personal_name: "Nume personal - TBC",
      personal_phone: "Număr telefon - TBC",
      seller: "Nume vânzător",
      total_price: "Preț total",
      price: "Preț",
      order_items: "Produse comandate",
      quantity: "Cantitate",
      product: "Produs",
      destination: "Destinație",
    },
    settings: {
      title: "Setări",
      edit_title: "Editare setări",
      show_title: "Vizualizare setări",
      newsletter: "Status buletin informativ",
      counter: "Număr abonați",
    },
    approval_status: {
      approved: "Aprobat",
      denied: "Respins",
      rejected: "Respins",
      pending: "În așteptare",
      suspended: "Suspendat",
      confirmed: "Confirmat",
      refused: "Respins",
      cancelled: "Anulat",
      paid: "Plătit",
      shipped: "Expediat",
      returned: "Returned",
    },
    general: {
      image_alternate_name: "Nume alternativ imagine",
      image_description: "Descrierea imaginii",
      image: "Imagine",
      author: "Autorul imaginii",
      url: "Pagină web",
      latitude: "Latitudine",
      longitude: "Longitudine",
      section_description: "Descrierea secțiunii",
      section_title: "Titlul secțiunii",
      section_listing: "Listare secțiune",
      general_description: "Descriere generală",
      description: "Descriere",
      back: "Înapoi",
    },
    validation: {
      required: "Obligatoriu",
      minLength: "Trebuie să fie cel puțin %{min} caractere",
      maxLength: "Trebuie să fie maxim %{max} csaractere",
      minValue: "Trebuie să fie cel puțin %{min}",
      maxValue: "Trebuie să fie %{max} sau mai mic",
      number: "Trebuie sa fie un număr",
      email: "Trebuie să fie un e-mail valid",
      oneOf: "Trebuie să fie una dintre: %{options}",
      maxRows: "Trebuie să conțină %{max} linii",
      minRows: "Trebuie să conțină %{min} linii",
      min_items: "Trebuie să conțină minimum 3 imagini",
      maxLengthPerRow:
        "Trebuie să conțină cel mult %{value} charactere per rand",
      invalidUrl: "URL invalid",
      regex: "Trebuie să corespundă unui format specific (regexp): %{pattern}",
      slug: "Slugul trebuie să urmeze următorul format: xxx-yyy-zzz",
      phone: "Număr telefon invalid",
    },
  },
  "Not Found": "Nu a fost găsit niciun rezultat",
  "Failed to fetch":
    "Eroare conexiune. Vă rugăm să vă asigurați că sunteți conectat la internet",
};

export default romanianMessages;
