/**
 * Component short description
 *
 * @see DestinationList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DestinationList.data";

/**
 * Imports Material UI components
 */

const DestinationList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      title={t("ra.destination.title")}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />
        <TextField
          source="name.ro"
          label={t("ra.destination.name")}
          sortable={false}
        />
        <TextField
          source="description.ro"
          label={t("ra.destination.description")}
          sortable={false}
        />
        <EditButton basePath="/destinations" />
      </Datagrid>
    </List>
  );
};

DestinationList.propTypes = propTypes;
DestinationList.defaultProps = defaultProps;

export default DestinationList;
export {
  propTypes as DestinationListPropTypes,
  defaultProps as DestinationListDefaultProps,
};
