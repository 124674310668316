/**
 * Component short description
 *
 * @see DateInput.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useCallback } from "react";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { InputHelperText } from "react-admin";
import { useInput, FieldTitle } from "ra-core";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DateInput.data";
import { sanitizeRestProps } from "../ReactAdmin/ReactAdmin.logic";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const Picker = (props) => {
  const {
    PickerComponent,
    label,
    source,
    resource,
    providerOptions,
    validate,
    helperText,
    className,
    ...rest
  } = props;

  const { input, isRequired, meta } = useInput({ source, validate });

  const { touched, error } = meta;

  const handleChange = useCallback((value) => {
    Date.parse(value)
      ? input.onChange(value.toISOString())
      : input.onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiPickersUtilsProvider {...providerOptions}>
      <PickerComponent
        {...sanitizeRestProps(rest)}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        error={!!(touched && error)}
        helperText={
          <InputHelperText
            touched={touched}
            error={error}
            helperText={helperText}
          />
        }
        value={input.value ? new Date(input.value) : null}
        onChange={(date) => handleChange(date)}
        onBlur={() =>
          input.onBlur(input.value ? new Date(input.value).toISOString() : null)
        }
      />
    </MuiPickersUtilsProvider>
  );
};

const DateInput = (props) => <Picker PickerComponent={DatePicker} {...props} />;
const TimeInput = (props) => <Picker PickerComponent={TimePicker} {...props} />;
const DateTimeInput = (props) => (
  <Picker PickerComponent={DateTimePicker} {...props} />
);

Picker.propTypes = propTypes;
Picker.defaultProps = defaultProps;

export default Picker;
export {
  DateInput,
  TimeInput,
  DateTimeInput,
  propTypes as DateInputPropTypes,
  defaultProps as DateInputDefaultProps,
};
