/**
 * Component short description
 *
 * @see FaqShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Show, SimpleShowLayout, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FaqShow.data";

/**
 * Imports Material UI components
 **/

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FaqShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.faq.show_title")}>
      <SimpleShowLayout>
        <MultilanguageTextField
          source="question"
          label={t("ra.faq.question")}
        />
        <MultilanguageTextField source="answer" label={t("ra.faq.answer")} />
      </SimpleShowLayout>
    </Show>
  );
};

FaqShow.propTypes = propTypes;
FaqShow.defaultProps = defaultProps;

export default FaqShow;
export { propTypes as FaqShowPropTypes, defaultProps as FaqShowDefaultProps };
