/**
 * Component short description
 *
 * @see EventCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";
import * as moment from "moment";
/**
 * Imports other components and hooks
 */
import EventForm from "../EventForm/EventForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventCreate.data";
import { parseImage } from "../../ReactAdmin/ReactAdmin.logic";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

const transformEvent = (data) => {
  const { image, image1, image2, startDate, endDate } = data;
  const newImage = parseImage(image);
  const newImage1 = parseImage(image1);
  const newImage2 = parseImage(image2);

  const newStartDate = moment(startDate).format("YYYY-MM-DD");
  const newEndDate = moment(endDate).format("YYYY-MM-DD");

  return {
    ...data,
    image: newImage,
    image1: newImage1,
    image2: newImage2,
    startDate: newStartDate,
    endDate: newEndDate,
  };
};
/**
 * Displays the component
 */
const EventCreate = (props) => {
  const t = useTranslate();
  return (
    <Create
      title={t("ra.event.create_title")}
      transform={transformEvent}
      {...props}
    >
      <EventForm />
    </Create>
  );
};

EventCreate.propTypes = propTypes;
EventCreate.defaultProps = defaultProps;

export default EventCreate;
export {
  transformEvent,
  propTypes as EventCreatePropTypes,
  defaultProps as EventCreateDefaultProps,
};
