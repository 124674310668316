/**
 * Component short description
 *
 * @see AccommodationList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  ReferenceField,
  BulkDeleteButton,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationList.data";

/**
 * Displays the component
 */
const AccommodationList = (props) => {
  const t = useTranslate();
  const { permissions } = props;

  return (
    <List
      {...props}
      title={t("ra.accommodation.title")}
      exporter={false}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />
        <ReferenceField
          sortable={false}
          reference="destinations"
          source="destinationId"
          label={t("ra.accommodation.destination")}
          link="show"
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <ReferenceField
          sortable={false}
          reference="stay/accommodation-types"
          source="accommodationTypeId"
          label={t("ra.accommodation.type")}
          link={null}
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <TextField
          source="name.ro"
          label={t("ra.accommodation.name")}
          sortable={false}
        />
        <ReferenceField
          reference="stay/hosts"
          source="hostId"
          label={t("ra.accommodation.host")}
          sortable={false}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField
          source="description.ro"
          label={t("ra.accommodation.description")}
          sortable={false}
        />
        <TextField
          source="price"
          label={t("ra.accommodation.price")}
          sortable={false}
        />
        {
          permissions?.isHost && <EditButton basePath="/stay/accommodations" />
        }
      </Datagrid>
    </List>
  );
};

AccommodationList.propTypes = propTypes;
AccommodationList.defaultProps = defaultProps;

export default AccommodationList;
export {
  propTypes as AccommodationListPropTypes,
  defaultProps as AccommodationListDefaultProps,
};
