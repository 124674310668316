/**
 * Defines the data requirements for the component
 *
 * @see MultilanguageTextField.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  sortable: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  source: "key",
  label: "Label",
  sortable: false,
};

export { propTypes, defaultProps };
