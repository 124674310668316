/**
 * Component short description
 *
 * @see EventList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  ReferenceField,
  BulkDeleteButton,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventList.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const EventList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      title={t("ra.event.title")}
      exporter={false}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />

        <TextField
          source="name.ro"
          label={t("ra.event.name")}
          sortable={false}
        />
        <ReferenceField
          reference="destinations"
          source="destinationId"
          label={t("ra.news.destination")}
          link="show"
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <EditButton basePath="/events" />
      </Datagrid>
    </List>
  );
};

EventList.propTypes = propTypes;
EventList.defaultProps = defaultProps;

export default EventList;
export {
  propTypes as EventListPropTypes,
  defaultProps as EventListDefaultProps,
};
