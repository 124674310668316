/**
 * Component short description
 *
 * @see SettingEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import SettingForm from "../SettingForm/SettingForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SettingEdit.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const SettingEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit title={t("ra.settings.edit_title")} {...props} undoable={false}>
      <SettingForm />
    </Edit>
  );
};

SettingEdit.propTypes = propTypes;
SettingEdit.defaultProps = defaultProps;

export default SettingEdit;
export {
  propTypes as SettingEditPropTypes,
  defaultProps as SettingEditDefaultProps,
};
