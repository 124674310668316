/**
 * Component short description
 *
 * @see AccommodationEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationEdit.data";
import { transformAccommodation } from "../AccommodationCreate/AccommodationCreate";
import AccommodationForm from "../AccommodationForm/AccommodationForm";

/**
 * Displays the component
 */
const AccommodationEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit
      {...props}
      title={t("ra.accommodation.edit_title")}
      undoable={false}
      transform={transformAccommodation}
    >
      <AccommodationForm />
    </Edit>
  );
};

AccommodationEdit.propTypes = propTypes;
AccommodationEdit.defaultProps = defaultProps;

export default AccommodationEdit;
export {
  propTypes as AccommodationEditPropTypes,
  defaultProps as AccommodationEditDefaultProps,
};
