/**
 * Component short description
 *
 * @see BooleanField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import get from "lodash/get";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./BooleanField.data";

/**
 * Imports Material UI components
 */
import { Typography } from "@material-ui/core";
import FalseIcon from "@material-ui/icons/Clear";
import TrueIcon from "@material-ui/icons/Done";
/**
 * Styles the component
 */

/**
 * Displays the component
 */
const BooleanField = ({ className, source, record = {}, ...rest }) => {
  if (get(record, source) === 0) {
    return (
      <Typography
        component="span"
        body1="body1"
        className={className}
        {...rest}
      >
        <FalseIcon />
      </Typography>
    );
  }

  if (get(record, source) === 1) {
    return (
      <Typography
        component="span"
        body1="body1"
        className={className}
        {...rest}
      >
        <TrueIcon />
      </Typography>
    );
  }

  return (
    <Typography
      component="span"
      body1="body1"
      className={className}
      {...rest}
    />
  );
};

BooleanField.propTypes = propTypes;
BooleanField.defaultProps = defaultProps;

export default BooleanField;
export {
  propTypes as BooleanFieldPropTypes,
  defaultProps as BooleanFieldDefaultProps,
};
