/**
 * Defines the data requirements for the component
 *
 * @see DateInput.md for details
 */
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import "moment/locale/ro";

import { DatePicker } from "@material-ui/pickers";
/**
 * Defines the prop types
 */
const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  pickerComponent: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  input: {},
  meta: { touched: false, error: false },
  fullWidth: true,
  inputVariant: "outlined",
  margin: "dense",
  resource: "",
  source: "",
  className: "",
  providerOptions: {
    utils: MomentUtils,
    locale: "ro",
  },
  PickerComponent: DatePicker,
  validate: [],
};

export { propTypes, defaultProps };
