/**
 * Component short description
 *
 * @see AttractionForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  TabbedForm,
  FormTab,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  FileInput,
  NumberInput,
  SelectInput,
  useTranslate,
  TabbedFormTabs,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionForm.data";
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import EditableImageField from "../../EditableImageField";
import { useValidation } from "../../../hooks";
import FilePreviewField from "../../FilePreviewField/FilePreviewField";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";
import useFormValidation from "../../../hooks/useFormValidation";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const AttractionForm = (props) => {
  const {
    validateGps,
    validateDescription,
    validateDropdown,
    validateTextGeneral,
    validateOpeningHours,
    validateCarouselImagesWithLength,
    validateLink,
    validateResolutions,
    validatePriceWithMin,
    getDescriptionValidation,
  } = useValidation();

  const t = useTranslate();
  const { authorIsVisible, handleValidate, handleImageValidate } = useFormValidation();

  return (
    <TabbedForm
      {...props}
      variant="outlined"
      redirect="show"
      onBlur={handleValidate}
      onClick={handleValidate}
      validate={handleImageValidate}
      tabs={<TabbedFormTabs variant="scrollable" />}
    >
      <FormTab label="Detalii">
        <ReferenceInput
          label={t("ra.attraction.destination")}
          reference="destinations"
          source="destinationId"
          fullWidth
        >
          <SelectInput
            optionText="name.ro"
            optionValue="id"
            validate={validateDropdown}
          />
        </ReferenceInput>

        <ReferenceInput
          label={t("ra.attraction.category")}
          reference="categories"
          source="categoryId"
          fullWidth
        >
          <SelectInput
            optionText="name.ro"
            optionValue="id"
            validate={validateDropdown}
          />
        </ReferenceInput>

        <MultilanguageTextInput
          source="name"
          label={t("ra.attraction.name")}
          fullWidth
          validate={validateTextGeneral}
        />
        <MultilanguageTextInput
          source="description"
          label={t("ra.attraction.description")}
          multiline
          validate={validateDescription}
          fullWidth
        />
        <TextInputWithCharacterCount
          source="address"
          label={t("ra.attraction.address")}
          fullWidth
          validate={validateTextGeneral}
        />
        <TextInput
          source="url"
          label={t("ra.attraction.url")}
          validate={validateLink}
          fullWidth
        />
        <TextInput
          source="facebook"
          label={t("ra.attraction.facebook")}
          validate={validateLink}
          fullWidth
        />
        <TextInput
          source="instagram"
          label={t("ra.attraction.instagram")}
          validate={validateLink}
          fullWidth
        />

        <NumberInput
          source="latitude"
          label={t("ra.general.latitude")}
          fullWidth
          variant="outlined"
          validate={validateGps}
        />
        <NumberInput
          source="longitude"
          label={t("ra.general.longitude")}
          fullWidth
          variant="outlined"
          validate={validateGps}
        />

        <MultilanguageTextInput
          source="openingHours"
          label={t("ra.attraction.opening_hours")}
          multiline
          rows={2}
          rowsMax={7}
          fullWidth
          validate={validateOpeningHours}
        />

        <MultilanguageTextInput
          source="prices"
          label={t("ra.attraction.price")}
          multiline
          rows={3}
          rowsMax={3}
          fullWidth
          validate={validatePriceWithMin}
        />

        <FileInput
          source="audio"
          label={t("ra.attraction.audio")}
          accept=".mp3,audio/*"
        >
          <FilePreviewField source="src" title="title" />
        </FileInput>
        <FileInput
          source="track"
          label={t("ra.attraction.track")}
          accept=".gpx,.kml"
        >
          <FilePreviewField source="src" title="title" />
        </FileInput>
      </FormTab>
      <FormTab label={t("ra.attraction.images")}>
        <ArrayInput source="images" validate={validateCarouselImagesWithLength}>
          <SimpleFormIterator>
            <TextInputWithCharacterCount
              source="author"
              label={t("ra.general.author")}
              fullWidth
              validate={validateTextGeneral}
            />
            <EditableImageField
              source="resolutions"
              label={t("ra.general.image")}
              validate={validateResolutions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="ra.general.general_description">
        <MultilanguageTextInput
          source="sectionDescription1"
          label={t("ra.general.description")}
          validate={getDescriptionValidation(500, 10000)}
          multiline
          fullWidth
        />

        {
          authorIsVisible &&
          <TextInputWithCharacterCount
            source="image1.author"
            label={t("ra.general.author")}
            validate={getDescriptionValidation(3, 128, false)}
            fullWidth
          />
        }
        <EditableImageField
          canBeDeleted={true}
          source="image1.resolutions"
          label={t("ra.general.image")}
        />
      </FormTab>
    </TabbedForm>
  );
};

AttractionForm.propTypes = propTypes;
AttractionForm.defaultProps = defaultProps;

export default AttractionForm;
export {
  propTypes as AttractionFormPropTypes,
  defaultProps as AttractionFormDefaultProps,
};
