import React, { useEffect, useState } from "react";
import { TextInput } from "react-admin";
import { getRecordFieldLength } from "../../helpers/baseHelper";

function TextInputWithCharacterCount(props) {
  const { source, label, validate, fullWidth, record } = props;
  const [minLength, maxLength] = validate;
  const [charactersLength, setCharactersLength] = useState(0);
  const hasMinMax = typeof minLength === 'number' && typeof maxLength === 'number';

  const handleChange = (value) => {
    if (hasMinMax) {
      setCharactersLength(value);
    }
  }

  const useEffectCallback = () => {
    if (!hasMinMax || !record) {
      return;
    }

    const recordFieldLength = getRecordFieldLength(source, record, 'author');
    if (recordFieldLength) {
      setCharactersLength(recordFieldLength)
    }

    if (record[source]) {
      setCharactersLength(record[source].length)
    }
  }

  useEffect(useEffectCallback, [record]);

  return (
      <TextInput
        {...props}
        source={source}
        label={label}
        fullWidth={fullWidth}
        validate={validate}
        helperText={hasMinMax && `${minLength}(min) / ${maxLength}(max) - ${charactersLength}(actual)`}
        onChange={(e) => handleChange(e.target.value.length)}
      />
  )
}

export default TextInputWithCharacterCount;
