import React, { useState, useRef, useEffect } from "react";

import { InputHelperText } from "react-admin";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Resizer from "react-image-file-resizer";
import { Field, useField } from "react-final-form";

import { makeStyles, FormHelperText } from "@material-ui/core";
import { Button, Typography, Grid } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import DeleteIcon from '@material-ui/icons/Delete';

import { propTypes, defaultProps } from "./EditableImageField.data";
import { composeValidators } from "../../hooks/useValidation/useValidation";

const useStyles = makeStyles((theme) => ({
  imageInput: {
    // maxWidth: "256px",
  },
  imageContainer: {
    position: "relative",
    display: "block",
    width: "100%",
    maxWidth: "256px",
    minHeight: "50px",
    marginLeft: "10px",
    marginRight: "auto",
  },
  imageContainerImage: {
    width: "100%",
    display: "block",
    backgroundColor: "#303030",
    padding: "5px",
  },
  imageContainerButtons: {
    width: "100%",
    margin: '10px'
  },
  button: {
    marginRight: '10px'
  },
  hidden: {
    display: "none",
  },
}));

const parseAspects = (options) => {
  return options.map((option) => {
    const { aspect } = option;

    return {
      ...option,
      crop: {
        unit: "%",
        height: 200,
        aspect: aspect,
      },
      croppedImage: null,
    };
  });
};

const setImageUpdated = (data, images) => {
};

/**
 * This component renders the current image of a record, if it has one. On top of this, it
 * renders a small upload button at the bottom right corner of the image in order to change
 * the record's image. For this, the new image gets loaded with the ReactCrop component.
 *
 * @param record is the record from the datagrid.
 * @param source is the source name for the image.
 * @param props are the remaining parent-level properties,
 */
const EditableImageField = ({
  record,
  source,
  validate,
  options,
  canBeDeleted,
}) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
    meta: { error, touched },
  } = useField(source, { validate: composeValidators(...validate) });

  const {
    input: { onChange: onImage1Change },
  } = useField('image1');

  const initialState = {
    // Contains the new (non-cropped) image
    src: null,
    name: "",
    resolutions: [],
    // Contains the reference to the cropped image (objectURL)
    croppedImageUrl: undefined,
    // Contains the actual cropped image blob
    blob: undefined,
    aspects: parseAspects(options),
    crop: {
      aspect: 16 / 9,
    },
    // Settings for the ReactCrop component. We want square images, so we set the aspect ratio to 1
  }

  // Contains the reference to the original image (objectURL)
  const imageRef = useRef(null);
  const [state, setState] = useState(initialState);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (state?.resolutions?.length) {
      onChange(state.resolutions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.resolutions]);

  useEffect(() => {
    if (record?.image1) {
      setIsVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Gets called when a file gets selected in the upload dialog. Sets the "src" property on the state object.
   * @param e
   */
  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const name = e.target.files[0].name;
      reader.addEventListener("load", () => {
        setState((prevState) => ({
          ...prevState,
          src: reader.result,
          blob: reader.result,
          name: name,
          resolutions: [],
        }));
      });
      reader.readAsDataURL(e.target.files[0]);
      setIsVisible(true);
    }
  };

  /**
   * Gets called when the image has been loaded
   */
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  /**
   * Gets called each time the crop's changes are done (crop window released)
   */
  const onCropComplete = (crop) => {
    const aspect = state.aspects.find((a) => {
      const { aspect } = a;
      return crop.aspect === aspect;
    });

    const { sizes } = aspect;
    makeClientCrop(imageRef.current, crop, sizes);
  };

  /**
   * Gets called each time the crop is changing (size, location, ...)
   */
  const onCropChange = (crop) => {
    setState((prevState) => ({
      ...prevState,
      aspects: prevState.aspects.map((a) => {
        const { aspect } = a;
        return aspect === crop.aspect
          ? {
            ...a,
            crop: crop,
          }
          : a;
      }),
    }));
  };

  /**
   * Generates the cropped preview image
   */
  const makeClientCrop = async (imageRef, crop, sizes) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.png",
        sizes
      );
      setState((prevState) => ({
        ...prevState,
        aspects: prevState.aspects.map((a) => {
          const { aspect } = a;
          return aspect === crop.aspect
            ? {
              ...a,
              croppedImageUrl: croppedImageUrl,
            }
            : a;
        }),
      }));
    }
  };

  /**
   * Transforms the original image to a cropped copy by applying the cropping parameters
   */
  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  const getCroppedImg = (image, crop, fileName, sizes) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    // maximum width/height
    var maxWidth = 5000,
      maxHeight = 5000 / (16 / 9);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    new Promise((resolve, reject) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        resizeImages(sizes, blob).then((images) => {
          setState((prevState) => ({
            ...prevState,
            resolutions: [...images],
          }));
        });
        resolve(blob);
      }, "image/jpeg");
    });
    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;
  };

  const resizeImages = async (sizes, img) => {
    return Promise.all(
      sizes.map(async (size) => {
        const image = await resizeImage(img, size);
        return {
          ...size,
          image,
        };
      })
    );
  };

  const resizeImage = (img, size) => {
    const { width, height } = size;
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        img,
        width,
        height,
        "JPEG",
        50,
        0,
        (url) => {
          resolve(url);
        },
        "base64"
      );
    });
  };

  const handleDeleteImage = () => {
    setState(initialState);
    setIsVisible(false);
    onImage1Change(null);
  }

  return (
    <div className={classes.imageInput}>
      {/*
            We need this react-final-form field because it handles the react-admin update process
            Each time the cropped image changes, the onChange() method gets called with the current image
            */}
      <Field name={source} component="input" className={classes.hidden} />
      {isVisible && (
        <div className={classes.imageContainer}>
          <ImagePreview record={value} state={state} />
        </div>
      )}

      {/*Hidden input for the image upload. We only accept png images*/}
      <input
        accept="image/*"
        className={classes.hidden}
        id={source}
        type="file"
        onChange={onSelectFile}
      />
      <div className={classes.imageContainerButtons}>
        {/*Material design upload button*/}
        <label htmlFor={source}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            startIcon={<CloudUpload />}
          >
            Incarcare imagine
          </Button>
        </label>

        {canBeDeleted && isVisible && (
          <Button
            variant="outlined"
            color="secondary"
            component="span"
            onClick={handleDeleteImage}
            startIcon={<DeleteIcon />}
          >
            Șterge imaginea
          </Button>
        )}
      </div>

      {/*When the image is selected and loaded, the ReactCrop component can be shown*/}
      {state.src && (
        <Grid container spacing={2}>
          {state.aspects.map((a) => {
            const { crop, aspectRatio } = a;
            return (
              <Grid item sm={4}>
                <Typography variant="body1">{aspectRatio}</Typography>
                <ReactCrop
                  src={state.src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {touched && error && (
        <Grid item xs={12}>
          <FormHelperText error className="MuiFormHelperText-contained">
            <InputHelperText
              touched={touched}
              error={error}
              helperText={error}
            />
          </FormHelperText>
        </Grid>
      )}
    </div>
  );
};

const ImagePreview = (props) => {
  const { record, state } = props;
  const { src } = state;
  const classes = useStyles();
  if (record.length && !src) {
    const src = record[0].path;
    return (
      <img src={src} alt="Preview" className={classes.imageContainerImage} />
    );
  }
  return null;
};

EditableImageField.propTypes = propTypes;
EditableImageField.defaultProps = defaultProps;

export default EditableImageField;
export {
  setImageUpdated,
  propTypes as EditableImageFieldPropTypes,
  defaultProps as EditableImageFieldDefaultProps,
};
