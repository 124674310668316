/**
 * Component short description
 *
 * @see FaqCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import FaqForm from "../FaqForm/FaqForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FaqCreate.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FaqCreate = (props) => {
  const t = useTranslate();
  return (
    <Create {...props} title={t("ra.faq.create_title")}>
      <FaqForm />
    </Create>
  );
};

FaqCreate.propTypes = propTypes;
FaqCreate.defaultProps = defaultProps;

export default FaqCreate;
export {
  propTypes as FaqCreatePropTypes,
  defaultProps as FaqCreateDefaultProps,
};
