/**
 * Component short description
 *
 * @see DestinationForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  BooleanInput,
  FormTab,
  NumberInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useTranslate,
  TabbedFormTabs,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import EditableImageField from "../../EditableImageField";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./DestinationForm.data";
import { useValidation } from "../../../hooks";
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import MultiLanguageFileInput from "../../MultiLanguageFileInput/MultiLanguageFileInput";
import ToolbarWithoutDelete from "../../ToolbarWithoutDelete/ToolbarWithoutDelete";
import useTabsValidation from "../../../hooks/useValidation/useTabsValidation";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const DestinationForm = (props) => {
  const t = useTranslate();
  const {
    validateTextGeneral,
    validateResolutions,
    validateGps,
    validateDescriptionWithLongMin,
    validateVideoUrl,
    validateLink,
    validateAlternateName,
    validateLongDescription,
  } = useValidation();
  const [toggleChange, setToggleChange] = useState(false);
  const { setTabsFormErrors } = useTabsValidation();

  useEffect(() => {
    setTabsFormErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleChange]);

  const handleValidate = () => {
    setToggleChange(!toggleChange);
  };

  return (
    <TabbedForm
      {...props}
      variant="outlined"
      toolbar={<ToolbarWithoutDelete />}
      tabs={<TabbedFormTabs variant="scrollable" />}
      redirect="show"
      onBlur={handleValidate}
      onClick={handleValidate}
    >
      <FormTab label={t("ra.destination.details")}>
        <BooleanInput label="Publicabil" source="isPublished" />
        <MultilanguageTextInput
          source="name"
          fullWidth
          label={t("ra.destination.name")}
          validate={validateTextGeneral}
          {...props}
        />
        <MultilanguageTextInput
          source="alternateName"
          fullWidth
          label={t("ra.destination.alternate_name")}
          validate={validateAlternateName}
        />
        <MultilanguageTextInput
          source="description"
          fullWidth
          multiline
          label={t("ra.destination.description")}
          validate={validateDescriptionWithLongMin}
        />

        <NumberInput
          source="latitude"
          label={t("ra.general.latitude")}
          fullWidth
          validate={validateGps}
        />

        <NumberInput
          source="longitude"
          label={t("ra.general.longitude")}
          fullWidth
          validate={validateGps}
        />

        <MultiLanguageFileInput
          source="pdf"
          label={t("ra.destination.pdf")}
          accept="application/pdf"
        />
        <MultiLanguageFileInput
          source="transportation"
          label={t("ra.destination.transportation")}
          accept="application/pdf"
        />
        <MultilanguageTextInput
          source="longDescription"
          multiline
          label={t("ra.destination.longDescription")}
          validate={validateLongDescription}
          fullWidth
        />
        <TextInput
          source="webcam"
          fullWidth
          label={t("ra.destination.webcam")}
          validate={validateLink}
        />
      </FormTab>
      <FormTab label={t("ra.destination.images")}>
        <ArrayInput
          source="images"
          validate={[required()]}
          label={t("ra.destination.images")}
        >
          <SimpleFormIterator>
            <MultilanguageTextInput
              source="alternateName"
              label={t("ra.general.image_alternate_name")}
              validate={validateAlternateName}
              fullWidth
            />

            <TextInputWithCharacterCount
              source="author"
              fullWidth
              label={t("ra.general.author")}
              validate={validateTextGeneral}
            />

            <EditableImageField
              source="resolutions"
              label={t("ra.general.image")}
              validate={validateResolutions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t("ra.destination.videos")}>
        <ArrayInput
          source="videos"
          label={t("ra.destination.videos")}
          validate={[required()]}
        >
          <SimpleFormIterator>
            <TextInput
              source="url"
              fullWidth
              label={t("ra.destination.video_url")}
              validate={validateVideoUrl}
            />
            <MultilanguageTextInput
              source="name"
              label={t("ra.destination.video_name")}
              validate={validateTextGeneral}
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
};

DestinationForm.propTypes = propTypes;
DestinationForm.defaultProps = defaultProps;

export default DestinationForm;
export {
  propTypes as DestinationFormPropTypes,
  defaultProps as DestinationFormDefaultProps,
};
