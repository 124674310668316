/**
 * Component short description
 *
 * @see InfoList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./InfoList.data";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
} from "react-admin";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const InfoList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      title={t("ra.info.title")}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />
        <TextField source="name" label={t("ra.info.name")} sortable={false} />
        <TextField
          source="text.ro"
          label={t("ra.info.description")}
          sortable={false}
        />

        <EditButton basePath="/info" />
      </Datagrid>
    </List>
  );
};

InfoList.propTypes = propTypes;
InfoList.defaultProps = defaultProps;

export default InfoList;
export { propTypes as InfoListPropTypes, defaultProps as InfoListDefaultProps };
