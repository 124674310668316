/**
 * Component short description
 *
 * @see SellerForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import { TextInput, SimpleForm, SelectInput, useTranslate } from "react-admin";
/**
 * Imports other components and hooks
 */
import { useValidation } from "../../../hooks";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SellerForm.data";
import ToolbarWithoutDelete from "../../ToolbarWithoutDelete/ToolbarWithoutDelete";
import useTabsValidation from "../../../hooks/useValidation/useTabsValidation";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const SellerForm = (props) => {
  const t = useTranslate();
  const { permissions } = props;
  const { validateTextGeneral, validateEmail, validatePhone } = useValidation();

  const statusList = [
    {
      id: "approved",
      name: t("ra.approval_status.approved"),
    },
    {
      id: "pending",
      name: t("ra.approval_status.pending"),
      disabled: true,
    },
    {
      id: "denied",
      name: t("ra.approval_status.rejected"),
    },
    {
      id: "suspended",
      name: t("ra.approval_status.suspended"),
    },
  ];

  const [toggleChange, setToggleChange] = useState(false);
  const { setTabsFormErrors } = useTabsValidation();

  useEffect(() => {
    setTabsFormErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleChange]);

  const handleValidate = () => {
    setToggleChange(!toggleChange);
  };

  return (
    <SimpleForm
      {...props}
      variant="outlined"
      redirect="show"
      toolbar={<ToolbarWithoutDelete />}
      onBlur={handleValidate}
      onClick={handleValidate}
    >
      {permissions?.isSeller ? (
        <TextInputWithCharacterCount
          fullWidth
          source="name"
          label="ra.seller.name"
          validate={validateTextGeneral}
        />
      ) : null}
      {permissions?.isSeller ? (
        <TextInput
          fullWidth
          source="email"
          label="ra.seller.email"
          validate={validateEmail}
        />
      ) : null}
      {permissions?.isSeller ? (
        <TextInput
          fullWidth
          source="phone"
          label="ra.seller.phone"
          validate={validatePhone}
        />
      ) : null}

      {permissions?.isLocalAdmin || permissions?.isSuperAdmin ? (
        <SelectInput
          source="approvalStatus"
          choices={statusList}
          label={t("ra.host.status")}
          fullWidth
        />
      ) : null}
      {permissions?.isSeller ? (
        <TextInput
          fullWidth
          source="sellerPaypal"
          label="ra.seller.paypal"
          validate={validateTextGeneral}
        />
      ) : null}
    </SimpleForm>
  );
};

SellerForm.propTypes = propTypes;
SellerForm.defaultProps = defaultProps;

export default SellerForm;
export {
  propTypes as SellerFormPropTypes,
  defaultProps as SellerFormDefaultProps,
};
