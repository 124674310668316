/**
 * Component short description
 *
 * @see SettingShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { TextField, Show, SimpleShowLayout, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import BooleanField from "../../BooleanField/BooleanField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SettingShow.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const SettingShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.faq.show_title")}>
      <SimpleShowLayout>
        <BooleanField
          source="isNewsletterEnabled"
          label={t("ra.settings.newsletter")}
          sortable={false}
        />
        <TextField
          source="newsletterCounter"
          label={t("ra.settings.counter")}
          sortable={false}
        />
      </SimpleShowLayout>
    </Show>
  );
};

SettingShow.propTypes = propTypes;
SettingShow.defaultProps = defaultProps;

export default SettingShow;
export {
  propTypes as SettingShowPropTypes,
  defaultProps as SettingShowDefaultProps,
};
