/**
 * Component short description
 *
 * @see EventEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import EventForm from "../EventForm/EventForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventEdit.data";
import { transformEvent } from "../EventCreate/EventCreate";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const EventEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit
      {...props}
      undoable={false}
      title={t("ra.event.edit_title")}
      transform={transformEvent}
    >
      <EventForm />
    </Edit>
  );
};
EventEdit.propTypes = propTypes;
EventEdit.defaultProps = defaultProps;

export default EventEdit;
export {
  propTypes as EventEditPropTypes,
  defaultProps as EventEditDefaultProps,
};
