/**
 * Component short description
 *
 * @see StatusField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./StatusField.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const StatusField = (props) => {
  const { source, record = {} } = props;
  const t = useTranslate();

  return <span>{t(`ra.approval_status.${record[source]}`)}</span>;
};

StatusField.propTypes = propTypes;
StatusField.defaultProps = defaultProps;

export default StatusField;
export {
  propTypes as StatusFieldPropTypes,
  defaultProps as StatusFieldDefaultProps,
};
