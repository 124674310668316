/**
 * Component short description
 *
 * @see HostEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import HostForm from "../HostForm/HostForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HostEdit.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HostEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit {...props} undoable={false} title={t("ra.host.edit_title")}>
      <HostForm permissions={props.permissions} />
    </Edit>
  );
};

HostEdit.propTypes = propTypes;
HostEdit.defaultProps = defaultProps;

export default HostEdit;
export { propTypes as HostEditPropTypes, defaultProps as HostEditDefaultProps };
