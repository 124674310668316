import React from "react";
import { CssBaseline } from "@material-ui/core";
import { useQuery } from "./hooks";
import ReactAdmin from "./components/ReactAdmin/ReactAdmin";

function App() {
  const { token } = useQuery();

  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", "{}");
  }

  return (
    <CssBaseline>
      <ReactAdmin />
    </CssBaseline>
  );
}

export default App;
