/**
 * Component short description
 *
 * @see HostApprovalForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HostApprovalForm.data";
import BackButton from "../../BackButton/BackButton";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HostApprovalToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      <BackButton style={{ marginLeft: 16 }} />
    </Toolbar>
  );
};

const HostApprovalForm = (props) => {
  const t = useTranslate();

  const statusList = [
    {
      id: "approved",
      name: t("ra.approval_status.approved"),
    },
    {
      id: "pending",
      name: t("ra.approval_status.pending"),
      disabled: true,
    },
    {
      id: "rejected",
      name: t("ra.approval_status.rejected"),
    },
    {
      id: "suspended",
      name: t("ra.approval_status.suspended"),
    },
  ];

  return (
    <SimpleForm
      {...props}
      variant="outlined"
      redirect={(id, basepath, resource) =>
        `/stay/hosts/${resource.hostId}/show`
      }
      toolbar={<HostApprovalToolbar />}
    >
      <ReferenceInput
        label={t("ra.host.destination")}
        reference="destinations"
        source="destinationId"
        disabled
        fullWidth
      >
        <SelectInput optionText="name.ro" optionValue="id" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={statusList}
        label={t("ra.host.status")}
        fullWidth
      />
      <TextInputWithCharacterCount
        source="reason"
        label={t("ra.host.reason")}
        fullWidth
        disabled
      />
    </SimpleForm>
  );
};

HostApprovalForm.propTypes = propTypes;
HostApprovalForm.defaultProps = defaultProps;

export default HostApprovalForm;
export {
  propTypes as HostApprovalFormPropTypes,
  defaultProps as HostApprovalFormDefaultProps,
};
