/**
 * Component short description
 *
 * @see PartnerCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import PartnerForm from "../PartnerForm/PartnerForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./PartnerCreate.data";

/**
 * Imports Material UI components
 */
const imageUpdated = (data) => {
  const resolutions = data?.logo?.resolutions;

  const isUpdated =
    resolutions && resolutions.length && resolutions[0].image ? true : false;
  return {
    ...data,
    logo: {
      ...data.logo,
      edit: isUpdated,
    },
  };
};
/**
 * Displays the component
 */
const PartnerCreate = (props) => {
  const t = useTranslate();
  return (
    <Create
      {...props}
      title={t("ra.partner.create_title")}
      transform={imageUpdated}
    >
      <PartnerForm />
    </Create>
  );
};

PartnerCreate.propTypes = propTypes;
PartnerCreate.defaultProps = defaultProps;

export default PartnerCreate;
export {
  propTypes as PartnerCreatePropTypes,
  defaultProps as PartnerCreateDefaultProps,
};
