/**
 * Component short description
 *
 * @see MultiLanguageFileInput.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { FileInput } from "react-admin";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./MultiLanguageFileInput.data";
/**
 * Imports Material UI components
 */
import { Box } from "@material-ui/core";
/**
 * Imports other components and hooks
 */
import FilePreviewField from "../FilePreviewField/FilePreviewField";

/**
 * Displays the component
 */
const MultiLanguageFileInput = props => {
  const { record, source, label } = props;

  return (
    <Box className="ra-field" display="flex" flexDirection="column">
      {
        Object.keys(record[source]).map(key => {
          return (
            <>
              <FileInput
                {...props}
                source={`${source}.${key}`}
                label={`${label} (${key.toUpperCase()})`}
              >
                <FilePreviewField source="src" title="title" />
              </FileInput>
            </>
          );
        })
      }
    </Box>
  );
};

MultiLanguageFileInput.propTypes = propTypes;
MultiLanguageFileInput.defaultProps = defaultProps;

export default MultiLanguageFileInput;
export {
  propTypes as MultiLanguageFileInputPropTypes,
  defaultProps as MultiLanguageFileInputDefaultProps
};
