/**
 * Component short description
 *
 * @see InfoEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./InfoEdit.data";

/**
 * Imports Material UI components
 */
import InfoForm from "../InfoForm/InfoForm";
import { parseImage } from "../../ReactAdmin/ReactAdmin.logic";

/**
 * Styles the component
 */

const transform = (data) => {
  const { image } = data;

  if (!image) {
    return data;
  }

  const parsedImage = parseImage(image);

  return {
    ...data,
    image: parsedImage,
  };
};

/**
 * Displays the component
 */
const InfoEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit
      title={t("ra.info.edit_title")}
      {...props}
      undoable={false}
      transform={transform}
    >
      <InfoForm />
    </Edit>
  );
};

InfoEdit.propTypes = propTypes;
InfoEdit.defaultProps = defaultProps;

export default InfoEdit;
export { propTypes as InfoEditPropTypes, defaultProps as InfoEditDefaultProps };
