/**
 * Component short description
 *
 * @see PartnerList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  BulkDeleteButton,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./PartnerList.data";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const PartnerList = (props) => {
  const t = useTranslate();

  return (
    <List
      {...props}
      exporter={false}
      title={t("ra.partner.title")}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField
          source="name.ro"
          label={t("ra.partner.name")}
          sortable={false}
        />
        <TextField source="url" label={t("ra.general.url")} sortable={false} />
        <EditButton basePath="/partners" />
      </Datagrid>
    </List>
  );
};

PartnerList.propTypes = propTypes;
PartnerList.defaultProps = defaultProps;

export default PartnerList;
export {
  propTypes as PartnerListPropTypes,
  defaultProps as PartnerListDefaultProps,
};
