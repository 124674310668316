/**
 * Component short description
 *
 * @see AttractionQrCodeField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import QRCode from "qrcode.react";
import { useTranslate } from "react-admin";
import * as uaWebView from "is-ua-webview";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionQrCodeField.data";

/**
 * Imports Material UI components
 */
import { Box, makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  qr: {
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },
}));
/**
 * Displays the component
 */
const AttractionQrCodeField = (props) => {
  const { record = {} } = props;
  const {
    dynamicLinks,
    name: { ro },
  } = record;
  const t = useTranslate();
  const { container, qr } = useStyles();

  const isWebView = uaWebView(navigator.userAgent);

  const downloadQR = (id) => {
    if (isWebView) {
      return;
    }
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${ro}-${id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const qrId = `qr-attraction-${record.id}`;

  return (
    <Box className={container}>
      <QRCode
        id={qrId}
        value={dynamicLinks}
        size={256}
        className={qr}
        onClick={() => {
          downloadQR(qrId);
        }}
      />

      {!isWebView && (
        <Button
          variant="body2"
          onClick={() => {
            downloadQR(qrId);
          }}
        >
          {t("ra.attraction.download_qr_code")}
        </Button>
      )}
    </Box>
  );
};

AttractionQrCodeField.propTypes = propTypes;
AttractionQrCodeField.defaultProps = defaultProps;

export default AttractionQrCodeField;
export {
  propTypes as AttractionQrCodeFieldPropTypes,
  defaultProps as AttractionQrCodeFieldDefaultProps,
};
