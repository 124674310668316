/**
 * Component short description
 *
 * @see EventForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  TabbedForm,
  FormTab,
  SelectInput,
  useTranslate,
  FormDataConsumer,
  ReferenceInput,
  TabbedFormTabs,
} from "react-admin";

/**
 * Imports other components and hooks
 */
import { useValidation } from "../../../hooks";
import EditableImageField from "../../EditableImageField";
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import { DateInput } from "../../DateInput/DateInput";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventForm.data";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";
import useFormValidation from "../../../hooks/useFormValidation";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const EventForm = (props) => {
  const t = useTranslate();

  const {
    validateTextGeneral,
    validateResolutions,
    validateDescription,
    validateDropdown,
    validateDate,
    getDescriptionValidation,
  } = useValidation();

  const { authorIsVisible, handleValidate, handleImageValidate } = useFormValidation();

  return (
    <TabbedForm
      {...props}
      variant="outlined"
      redirect="show"
      onBlur={handleValidate}
      onClick={handleValidate}
      validate={handleImageValidate}
      tabs={<TabbedFormTabs variant="scrollable" />}
    >
      <FormTab label={t("ra.event.details")}>
        <ReferenceInput
          label={t("ra.event.destination")}
          reference="destinations"
          source="destinationId"
          fullWidth
        >
          <SelectInput
            optionText="name.ro"
            optionValue="id"
            validate={validateDropdown}
          />
        </ReferenceInput>
        <MultilanguageTextInput
          source="name"
          label={t("ra.event.name")}
          validate={validateTextGeneral}
          fullWidth
        />
        <DateInput
          source="startDate"
          label={t("ra.event.start_date")}
          validate={validateDate}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <DateInput
              source="endDate"
              minDate={formData.startDate}
              label={t("ra.event.end_date")}
              validate={validateDate}
            />
          )}
        </FormDataConsumer>
        <MultilanguageTextInput
          source="description"
          label={t("ra.event.description")}
          validate={validateDescription}
          multiline
          fullWidth
        />

        <TextInputWithCharacterCount
          source="image.author"
          label={t("ra.general.author")}
          validate={validateTextGeneral}
          fullWidth
        />
        <EditableImageField
          source="image.resolutions"
          label={t("ra.event.main_image")}
          validate={validateResolutions}
        />
      </FormTab>
      <FormTab label={t("ra.general.general_description")}>
        <MultilanguageTextInput
          source="sectionDescription1"
          label={t("ra.general.description")}
          validate={getDescriptionValidation(500, 10000)}
          multiline
          fullWidth
        />
        {
          authorIsVisible &&
          <TextInputWithCharacterCount
            source="image1.author"
            label={t("ra.general.author")}
            validate={getDescriptionValidation(3, 128, false)}
            fullWidth
          />
        }
        <EditableImageField
          canBeDeleted={true}
          source="image1.resolutions"
          label={t("ra.general.image")}
        />
      </FormTab>
    </TabbedForm>
  );
};

EventForm.propTypes = propTypes;
EventForm.defaultProps = defaultProps;

export default EventForm;
export {
  propTypes as EventFormPropTypes,
  defaultProps as EventFormDefaultProps,
};
