/**
 * Component short description
 *
 * @see AttractionEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionEdit.data";
import AttractionForm from "../AttractionForm";
import { transformAttraction } from "../AttractionCreate/AttractionCreate";

/**
 * Imports Material UI components
 */

/*
 * Displays the component
 */
const AttractionEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit
      {...props}
      title={t("ra.attraction.edit_title")}
      undoable={false}
      transform={transformAttraction}
    >
      <AttractionForm />
    </Edit>
  );
};

AttractionEdit.propTypes = propTypes;
AttractionEdit.defaultProps = defaultProps;

export default AttractionEdit;
export {
  propTypes as AttractionEditPropTypes,
  defaultProps as AttractionEditDefaultProps,
};
