/**
 * Component short description
 *
 * @see AttractionShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  NumberField,
  UrlField,
  ArrayField,
  ImageField,
  Datagrid,
  ReferenceField,
  useTranslate,
  TabbedShowLayoutTabs,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionShow.data";
import AttractionQrCodeField from "../AttractionQrCodeField/AttractionQrCodeField";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const AttractionShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.attraction.show_title")}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab label={t("ra.attraction.details")}>
          <ReferenceField
            reference="destinations"
            source="destinationId"
            label={t("ra.attraction.destination")}
            link="show"
          >
            <TextField source="name.ro" />
          </ReferenceField>
          <ReferenceField
            link={null}
            reference="categories"
            source="categoryId"
            label={t("ra.attraction.category")}
          >
            <TextField source="name.ro" />
          </ReferenceField>
          <MultilanguageTextField
            source="name"
            label={t("ra.attraction.name")}
          />
          <MultilanguageTextField
            source="description"
            label={t("ra.attraction.description")}
          />
          <TextField source="address" label={t("ra.attraction.address")} />
          <UrlField source="url" label={t("ra.attraction.url")} />
          <UrlField source="facebook" label={t("ra.attraction.facebook")} />
          <UrlField source="instagram" label={t("ra.attraction.instagram")} />

          <NumberField source="latitude" label={t("ra.general.latitude")} />
          <NumberField source="longitude" label={t("ra.general.longitude")} />
          <MultilanguageTextField
            source="openingHours"
            label={t("ra.attraction.opening_hours")}
          />

          <MultilanguageTextField
            source="prices"
            label={t("ra.attraction.price")}
          />

          <UrlField source="audio" label={t("ra.attraction.audio")} />
          <UrlField source="track" label={t("ra.attraction.track")} />
          <AttractionQrCodeField source="dynamicLinks" label="ra.attraction.qr_code" />
        </Tab>
        <Tab label={t("ra.attraction.images")}>
          <ArrayField source="images">
            <Datagrid>
              <MultilanguageTextField
                source="alternateName"
                label={t("ra.general.image_alternate_name")}
              />
              <TextField source="author" label={t("ra.general.author")} />
              <ImageField
                source="resolutions[0].path"
                label={t("ra.general.image")}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={t("ra.general.general_description")}>
          <MultilanguageTextField
            source="sectionDescription1"
            label={t("ra.general.description")}
          />
          <MultilanguageTextField
            source="image1.alternateName"
            label={t("ra.general.image_alternate_name")}
          />
          <TextField source="image1.author" label={t("ra.general.author")} />
          <ImageField
            source="image1.resolutions[0].path"
            label={t("ra.general.image")}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

AttractionShow.propTypes = propTypes;
AttractionShow.defaultProps = defaultProps;

export default AttractionShow;
export {
  propTypes as AttractionShowPropTypes,
  defaultProps as AttractionShowDefaultProps,
};
