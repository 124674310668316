/**
 * Defines the data requirements for the component
 *
 * @see EditableImageField.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  addLabel: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      aspect: PropTypes.number,
      sizes: PropTypes.arrayOf(
        PropTypes.shape({ width: PropTypes.number, height: PropTypes.number })
      ),
    })
  ),
  noSource: PropTypes.bool,
  validate: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  addLabel: true,
  options: [
    {
      aspect: 48 / 27,
      aspectRatio: "16:9",
      sizes: [
        {
          width: 160,
          height: 90,
        },
        {
          width: 320,
          height: 180,
        },
        {
          width: 640,
          height: 360,
        },
        {
          width: 1280,
          height: 720,
        },
      ],
    },
  ],
  noSource: false,
  validate: [],
};

export { propTypes, defaultProps };
