export default {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENDPOINT}auth/token`,
      {
        method: "POST",
        body: JSON.stringify({ email: username, password, isFromAdmin: true }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, user }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return Promise.resolve();
  },
  checkError: (error) => {
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const permissions = localStorage.getItem("user");

    const parsedPermissions = permissions
      ? JSON.parse(permissions)
      : localStorage.getItem("token")
      ? {}
      : null;

    return parsedPermissions
      ? Promise.resolve({ ...parsedPermissions })
      : Promise.reject();
  },
};
