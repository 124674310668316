/**
 * Component short description
 *
 * @see ProductShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TabbedShowLayoutTabs,
  ArrayField,
  ImageField,
  Datagrid,
  ReferenceField,
  FunctionField,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductShow.data";

/**
 * Displays the component
 */
const ProductShow = (props) => {
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.product.show_title")}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab label={t("ra.attraction.details")}>
          <TextField
            label="ra.product.destination"
            source="destinationName.ro"
          />
          <ReferenceField
            link={null}
            reference="market/categories"
            source="productCategoryId"
            label={t("ra.product.category")}
          >
            <TextField source="name.ro" />
          </ReferenceField>
          <ReferenceField
            reference="market/sellers"
            source="sellerId"
            label={t("ra.product.seller")}
            sortable={false}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <MultilanguageTextField source="name" label={t("ra.product.name")} />
          <MultilanguageTextField
            source="description"
            label={t("ra.product.description")}
          />
          <TextField source="price" label={t("ra.product.price")} />
          <ReferenceField
            link={null}
            reference="market/units"
            source="unitId"
            label={t("ra.product.unit")}
          >
            <TextField source="name.ro" />
          </ReferenceField>
          <FunctionField
            render={(record) => record.min || "-"}
            label={t("ra.product.min")}
          />
          <FunctionField
            render={(record) => record.max || "-"}
            label={t("ra.product.max")}
          />
        </Tab>
        <Tab label={t("ra.product.images")}>
          <ArrayField source="images">
            <Datagrid>
              <MultilanguageTextField
                source="alternateName"
                label={t("ra.general.image_alternate_name")}
              />
              <TextField source="author" label={t("ra.general.author")} />
              <ImageField
                source="resolutions[0].path"
                label={t("ra.general.image")}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={t("ra.general.general_description")}>
          <MultilanguageTextField
            source="sectionDescription1"
            label={t("ra.general.description")}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

ProductShow.propTypes = propTypes;
ProductShow.defaultProps = defaultProps;

export default ProductShow;
export {
  propTypes as ProductShowPropTypes,
  defaultProps as ProductShowDefaultProps,
};
