/**
 * Component short description
 *
 * @see OrderShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  SimpleShowLayout,
  ArrayField,
  useTranslate,
  TextField,
  NumberField,
  DateField,
  Datagrid,
  ShowController,
  ShowView,
} from "react-admin";

/**
 * Imports other components and hooks
 */
import StatusField from "../../StatusField/StatusField";
import ConditionalReferenceField from "../../ConditionalReferenceField/ConditionalReferenceField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderShow.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const OrderShow = (props) => {
  const t = useTranslate();
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView
          {...props}
          {...controllerProps}
          title={t("ra.order.show_title")}
        >
          <SimpleShowLayout>
            <ConditionalReferenceField
              reference="market/sellers"
              source="sellerId"
              label={t("ra.order.seller")}
              link="show"
              sortable={false}
              referenceSource="name"
              fallbackSource="sellerName"
            />
            <TextField
              source="deliveryName"
              label={t("ra.order.delivery_name")}
              sortable={false}
            />
            <TextField
              source="deliveryAddress"
              label={t("ra.order.delivery_address")}
              sortable={false}
            />
            <TextField
              source="deliveryPhone"
              label={t("ra.order.delivery_phone")}
              sortable={false}
            />

            <TextField
              source="personalName"
              label={t("ra.order.personal_name")}
              sortable={false}
            />
            <TextField
              source="personalAddress"
              label={t("ra.order.personal_address")}
              sortable={false}
            />
            <TextField
              source="personalPhone"
              label={t("ra.order.personal_phone")}
              sortable={false}
            />
            <NumberField
              source="shippingCost"
              label="ra.order.cost"
              sortable={false}
            />
            <NumberField
              source="totalPrice"
              label="ra.order.total_price"
              sortable={false}
            />
            <DateField
              source="orderDate"
              label="ra.order.order_date"
              sortable={false}
            />

            <StatusField
              source="status"
              label="ra.order.status"
              sortable={false}
            />
            {controllerProps?.record?.status === "refused" ? (
              <TextField
                source="refusalReason"
                label={t("ra.order.refusal_reason")}
                sortable={false}
              />
            ) : null}

            <ArrayField source="orderItems" label="ra.order.order_items">
              <Datagrid>
                <TextField source="id" sortable={false} />
                <ConditionalReferenceField
                  reference="market/products"
                  source="productId"
                  label={t("ra.order.product")}
                  link="show"
                  sortable={false}
                  referenceSource="name.ro"
                  fallbackSource="productName.ro"
                />
                <TextField
                  source="destinationName.ro"
                  label={t("ra.order.destination")}
                  sortable={false}
                />
                <NumberField
                  source="quantity"
                  label="ra.order.quantity"
                  sortable={false}
                />
                <NumberField
                  source="price"
                  label="ra.order.price"
                  sortable={false}
                />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

OrderShow.propTypes = propTypes;
OrderShow.defaultProps = defaultProps;

export default OrderShow;
export {
  propTypes as OrderShowPropTypes,
  defaultProps as OrderShowDefaultProps,
};
