/**
 * Component short description
 *
 * @see ProductForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import {
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TabbedFormTabs,
  useTranslate,
} from "react-admin";

/**
 * Imports other components and hooks
 */
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import EditableImageField from "../../EditableImageField";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductForm.data";
import { useValidation } from "../../../hooks";
import DropdownSingleSelect from "../../DropdownSingleSelect/DropdownSingleSelect";
import useTabsValidation from "../../../hooks/useValidation/useTabsValidation";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Displays the component
 */
const ProductForm = (props) => {
  const {
    validateTextGeneral,
    validateShortDescription,
    validatePrice,
    validateCarouselImages,
    validateResolutions,
    validateProductQuantity,
    getDescriptionValidation,
  } = useValidation();

  const t = useTranslate();
  const [toggleChange, setToggleChange] = useState(false);
  const { setTabsFormErrors } = useTabsValidation();

  useEffect(() => {
    setTabsFormErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleChange]);

  const handleValidate = () => {
    setToggleChange(!toggleChange);
  };

  return (
    <TabbedForm
      {...props}
      variant="outlined"
      onBlur={handleValidate}
      onClick={handleValidate}
      tabs={<TabbedFormTabs variant="scrollable" />}
    >
      <FormTab label={t("ra.product.details")}>
        <DropdownSingleSelect
          label={t("ra.product.category")}
          reference="market/categories"
          source="productCategoryId"
          fullWidth
        />
        <MultilanguageTextInput
          source="name"
          label={t("ra.product.name")}
          fullWidth
          validate={validateTextGeneral}
        />
        <MultilanguageTextInput
          source="description"
          label={t("ra.product.description")}
          fullWidth
          multiline
          validate={validateShortDescription}
        />
        <NumberInput
          source="price"
          label={t("ra.product.price")}
          fullWidth
          min="0"
          variant="outlined"
          validate={validatePrice}
        />
        <DropdownSingleSelect
          label="ra.product.unit"
          source="unitId"
          reference="market/units"
          fullWidth
        />
        <NumberInput
          source="min"
          label={t("ra.product.min")}
          fullWidth
          variant="outlined"
          min="0"
          validate={validateProductQuantity}
        />
        <NumberInput
          source="max"
          label={t("ra.product.max")}
          fullWidth
          variant="outlined"
          min="0"
          validate={validateProductQuantity}
        />
      </FormTab>
      <FormTab label={t("ra.product.images")}>
        <ArrayInput source="images" validate={validateCarouselImages}>
          <SimpleFormIterator>
            <TextInputWithCharacterCount
              source="author"
              label={t("ra.general.author")}
              fullWidth
              validate={validateTextGeneral}
            />
            <EditableImageField
              source="resolutions"
              label={t("ra.general.image")}
              validate={validateResolutions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t("ra.general.general_description")}>
        <MultilanguageTextInput
          source="sectionDescription1"
          label={t("ra.general.description")}
          validate={getDescriptionValidation(500, 10000)}
          multiline
          fullWidth
        />
      </FormTab>
    </TabbedForm>
  );
};

ProductForm.propTypes = propTypes;
ProductForm.defaultProps = defaultProps;

export default ProductForm;
export {
  propTypes as ProductFormPropTypes,
  defaultProps as ProductFormDefaultProps,
};
