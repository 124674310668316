/**
 * Component short description
 *
 * @see HostList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  EmailField,
  useTranslate,
  BulkDeleteButton,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HostList.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HostList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      exporter={false}
      title={t("ra.host.title")}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="name" label={t("ra.host.name")} sortable={false} />
        <EmailField
          source="email"
          label={t("ra.host.email")}
          sortable={false}
        />
        <TextField source="phone" label={t("ra.host.phone")} sortable={false} />
        <EditButton basePath="/stay/hosts" />
      </Datagrid>
    </List>
  );
};

HostList.propTypes = propTypes;
HostList.defaultProps = defaultProps;

export default HostList;
export { propTypes as HostListPropTypes, defaultProps as HostListDefaultProps };
