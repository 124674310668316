/**
 * Component short description
 *
 * @see NewsList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  ReferenceField,
  BulkDeleteButton,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./NewsList.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const NewsList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      title={t("ra.news.title")}
      exporter={false}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />

        <TextField
          source="name.ro"
          label={t("ra.news.name")}
          sortable={false}
        />
        <ReferenceField
          reference="destinations"
          source="destinationId"
          label={t("ra.news.destination")}
          link="show"
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <EditButton basePath="/news" />
      </Datagrid>
    </List>
  );
};

NewsList.propTypes = propTypes;
NewsList.defaultProps = defaultProps;

export default NewsList;
export { propTypes as NewsListPropTypes, defaultProps as NewsListDefaultProps };
