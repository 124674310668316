/**
 * Component short description
 *
 * @see MultilanguageTextInput.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import { TextInput } from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./MultilanguageTextInput.data";

/**
 * Imports Material UI components
 */
import { Box } from "@material-ui/core";
import { getRecordFieldByLanguages, LANGUAGES } from "../../helpers/baseHelper";

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const MultilanguageTextInput = (props) => {
  const { label, source, record, validate } = props;
  const [minLength, maxLength] = validate;
  const [charactersLength, setCharactersLength] = useState({ ro: 0, hu: 0, de: 0, en: 0 });
  const hasMinMax = typeof minLength === 'number' && typeof maxLength === 'number';

  const handleChange = (value, language) => {
    if (hasMinMax) {
      setCharactersLength({ ...charactersLength, [language]: value });
    }
  }

  const useEffectCallback = () => {
    if (!hasMinMax || !record) {
      return;
    }

    const newCharactersLengths = getRecordFieldByLanguages(source, record);

    setCharactersLength(newCharactersLengths)
  }

  useEffect(useEffectCallback, [record]);

  return (
    <Box className="ra-input" display="flex" flexDirection="column">
      {
        LANGUAGES.map((language, index) => (
          <TextInput
            {...props}
            key={language + index}
            source={`${source}.${language}`}
            label={`${label} (${language.toUpperCase()})`}
            helperText={hasMinMax && `${minLength}(min) / ${maxLength}(max) - ${charactersLength[language]}(actual)`}
            onChange={(e) => handleChange(e.target.value.length, language)}
          />
        ))
      }
    </Box>
  );
};

MultilanguageTextInput.propTypes = propTypes;
MultilanguageTextInput.defaultProps = defaultProps;

export default MultilanguageTextInput;
export {
  propTypes as MultilanguageTextInputPropTypes,
  defaultProps as MultilanguageTextInputDefaultProps,
};
