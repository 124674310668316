/**
 * Component short description
 *
 * @see DestinationEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";
import _ from 'lodash';
/**
 * Imports other components and hooks
 */
import DestinationForm from "../DestinationForm/DestinationForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DestinationEdit.data";
import { fileToBase64, parseImage } from "../../ReactAdmin/ReactAdmin.logic";

/**
 * Imports Material UI components
 */

const transform = async (data) => {
  if (!data) {
    return;
  }

  let { pdf, transportation, images } = data;
  pdf = await transformFilesByLanguage(pdf);
  transportation = await transformFilesByLanguage(transportation);
  images = images?.map((image) => parseImage(image));

  return {
    ...data,
    pdf,
    images,
    transportation,
  };
};

const transformFilesByLanguage = async (data) => {
  const newData = _.cloneDeep(data);
  const keys = Object.keys(newData);

  for (let index = 0; index < keys.length; index++) {
    const languageKey = keys[index];
    const dataByLanguage = newData[languageKey];

    if (dataByLanguage && dataByLanguage.src) {
      newData[languageKey] = await fileToBase64(dataByLanguage['rawFile'])
    }
  }

  return newData;
}

/**
 * Renders the component
 */
const DestinationEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit
      title={t("ra.destination.edit_title")}
      {...props}
      undoable={false}
      transform={transform}
    >
      <DestinationForm />
    </Edit>
  );
};

DestinationEdit.propTypes = propTypes;
DestinationEdit.defaultProps = defaultProps;

export default DestinationEdit;
export {
  propTypes as DestinationEditPropTypes,
  defaultProps as DestinationEditDefaultProps,
};
