/**
 * Component short description
 *
 * @see NewsShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TabbedShowLayoutTabs,
  ImageField,
  ReferenceField,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./NewsShow.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const NewsShow = (props) => {
  const t = useTranslate();

  return (
    <Show {...props} title={t("ra.news.show_title")}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab label={t("ra.news.details")}>
          <ReferenceField
            reference="destinations"
            source="destinationId"
            label={t("ra.news.destination")}
            link="show"
          >
            <TextField source="name.ro" />
          </ReferenceField>

          <MultilanguageTextField source="name" label={t("ra.news.name")} />
          <MultilanguageTextField
            source="description"
            label={t("ra.news.description")}
          />
          <MultilanguageTextField
            source="image.alternateName"
            label={t("ra.news.main_image_alternate_name")}
          />
          <TextField source="image.author" label={t("ra.general.author")} />
          <ImageField
            source="image.resolutions[0].path"
            label={t("ra.news.main_image")}
          />
        </Tab>
        <Tab label={t("ra.general.general_description")}>
          <MultilanguageTextField
            source="sectionDescription1"
            label={t("ra.general.description")}
          />
          <MultilanguageTextField
            source="image1.alternateName"
            label={t("ra.general.image_alternate_name")}
          />
          <TextField source="image1.author" label={t("ra.general.author")} />
          <ImageField
            source="image1.resolutions[0].path"
            label={t("ra.general.image")}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

NewsShow.propTypes = propTypes;
NewsShow.defaultProps = defaultProps;

export default NewsShow;
export { propTypes as NewsShowPropTypes, defaultProps as NewsShowDefaultProps };
