/**
 * Component short description
 *
 * @see FilePreviewField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { FileField } from "react-admin";
/**
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilePreviewField.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

const getFileName = (file) => {
  if (file.type) {
    return null;
  }

  const [name] = file.split("/").reverse();
  return name;
};
/**
 * Displays the component
 */

const FilePreviewField = (props) => {
  const { record = {} } = props;
  const file = record?.src ? (
    <FileField {...props} />
  ) : (
    <a href={record} target="_blank" rel="noopener noreferrer">
      {getFileName(record)}
    </a>
  );
  return <> {file} </>;
};

FilePreviewField.propTypes = propTypes;
FilePreviewField.defaultProps = defaultProps;

export default FilePreviewField;
export {
  propTypes as FilePreviewFieldPropTypes,
  defaultProps as FilePreviewFieldDefaultProps,
};
