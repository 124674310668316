/**
 * Component short description
 *
 * @see AttractionCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import AttractionForm from "../AttractionForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionCreate.data";
import { parseImage, fileToBase64 } from "../../ReactAdmin/ReactAdmin.logic";

/**
 * Imports Material UI components
 */
const getExtension = (file) => {
  const [extension] = file.split(".").reverse();

  return extension;
};

const transformAttraction = async (data) => {
  const images = data?.images?.map((image) => {
    return parseImage(image);
  });

  const image1 = parseImage(data.image1);
  const image2 = parseImage(data.image2);
  const audio = data?.audio?.src
    ? await fileToBase64(data.audio.rawFile)
    : data.audio;

  let track;
  if (data?.track?.src) {
    track = {
      type: getExtension(data.track.title),
      data: await fileToBase64(data.track.rawFile),
    };
  } else if (data?.track) {
    track = {
      type: getExtension(data.track),
      data: data.track,
    };
  } else {
    track = null;
  }

  const newData = {
    ...data,
    images,
    audio,
    image1,
    image2,
    track,
  };

  data.opening_hours === "" && delete newData.opening_hours;
  return newData;
};
/**
 * Displays the component
 */
const AttractionCreate = (props) => {
  const t = useTranslate();
  return (
    <Create
      title={t("ra.attraction.create_title")}
      {...props}
      transform={transformAttraction}
    >
      <AttractionForm />
    </Create>
  );
};

AttractionCreate.propTypes = propTypes;
AttractionCreate.defaultProps = defaultProps;

export default AttractionCreate;
export {
  transformAttraction,
  propTypes as AttractionCreatePropTypes,
  defaultProps as AttractionCreateDefaultProps,
};
