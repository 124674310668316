/**
 * Component short description
 *
 * @see OrderForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  FormDataConsumer,
  SimpleForm,
  SelectInput,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import { useValidation } from "../../../hooks";
import ToolbarWithoutDelete from "../../ToolbarWithoutDelete/ToolbarWithoutDelete";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderForm.data";
import { NumberInput } from "react-admin";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */

const OrderForm = (props) => {
  const t = useTranslate();
  const { permissions, record } = props;
  const { status } = record;
  const {
    validateProductPrice,
    validateDropdown,
    validateDescription,
  } = useValidation();

  const statusList = [
    {
      id: "confirmed",
      name: t("ra.approval_status.confirmed"),
      disabled: status === "pending" ? false : true,
    },
    {
      id: "pending",
      name: t("ra.approval_status.pending"),
      disabled: true,
    },
    {
      id: "refused",
      name: t("ra.approval_status.refused"),
      disabled: status === "pending" ? false : true,
    },
    {
      id: "cancelled",
      name: t("ra.approval_status.cancelled"),
      disabled: true,
    },
    {
      id: "paid",
      name: t("ra.approval_status.paid"),
      disabled: true,
    },
    {
      id: "shipped",
      name: t("ra.approval_status.shipped"),
      disabled: status === "paid" ? false : true,
    },
    {
      id: "returned",
      name: t("ra.approval_status.returned"),
      disabled: true,
    },
  ];

  return (
    <SimpleForm
      {...props}
      variant="outlined"
      redirect="show"
      toolbar={<ToolbarWithoutDelete />}
    >
      {permissions?.isSeller ? (
        <SelectInput
          source="status"
          choices={statusList}
          label={t("ra.host.status")}
          fullWidth
          validation={validateDropdown}
        />
      ) : null}

      {permissions?.isSeller ? (
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData.status === "confirmed" ? (
              <NumberInput
                fullWidth
                source="shippingCost"
                label="ra.order.cost"
                validate={validateProductPrice}
                {...rest}
              />
            ) : null
          }
        </FormDataConsumer>
      ) : null}
      {permissions?.isSeller ? (
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData.status === "refused" ? (
              <TextInputWithCharacterCount
                fullWidth
                source="refusalReason"
                label="ra.order.refusal_reason"
                validate={validateDescription}
                {...rest}
              />
            ) : null
          }
        </FormDataConsumer>
      ) : null}
    </SimpleForm>
  );
};

OrderForm.propTypes = propTypes;
OrderForm.defaultProps = defaultProps;

export default OrderForm;
export {
  propTypes as OrderFormPropTypes,
  defaultProps as OrderFormDefaultProps,
};
