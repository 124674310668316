/**
 * Component short description
 *
 * @see SettingForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { BooleanInput, SimpleForm, useTranslate, NumberInput } from "react-admin";
/**
 * Imports other components and hooks
 */
import ToolbarWithoutDelete from "../../ToolbarWithoutDelete/ToolbarWithoutDelete";
import { useValidation } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SettingForm.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const SettingForm = (props) => {
  const t = useTranslate();
  const { validateIsPositiveNumber } = useValidation();

  return (
    <SimpleForm
      {...props}
      variant="outlined"
      redirect="show"
      toolbar={<ToolbarWithoutDelete />}
    >
      <BooleanInput
        label="ra.settings.newsletter"
        source="isNewsletterEnabled"
      />
      <NumberInput
        source="newsletterCounter"
        label={t("ra.settings.counter")}
        fullWidth
        variant="outlined"
        validate={validateIsPositiveNumber}
      />
    </SimpleForm>
  );
};

SettingForm.propTypes = propTypes;
SettingForm.defaultProps = defaultProps;

export default SettingForm;
export {
  propTypes as SettingFormPropTypes,
  defaultProps as SettingFormDefaultProps,
};
