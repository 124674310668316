/**
 * Component short description
 *
 * @see Sidebar.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Sidebar as RASidebar } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Sidebar.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  drawerPaper: (props) => ({
    height: props.hasNavBar ? "calc(100vh - 75px)" : "100vh",
  }),
}));

const isSafariIphone = () => {
  var userAgent = window.navigator.userAgent;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  }
  return false;
};
/**
 * Displays the component
 */
const Sidebar = (props) => {
  const { hasNavigation, ...rest } = props;
  const isSafari = isSafariIphone();

  const hasNavBar = isSafari && hasNavigation;

  const classes = useStyles({ hasNavBar });

  return <RASidebar classes={classes} {...rest} />;
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
export { propTypes as SidebarPropTypes, defaultProps as SidebarDefaultProps };
