/**
 * Component short description
 *
 * @see DestinationShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  NumberField,
  UrlField,
  ArrayField,
  ImageField,
  Datagrid,
  useTranslate,
  TabbedShowLayoutTabs,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DestinationShow.data";
import BooleanField from "../../BooleanField/BooleanField";
import MultilanguageTextField from "../../MultilanguageTextField/MultilanguageTextField";
import MultiLanguageLinkField from "../../MultiLanguageLinkField/MultiLanguageLinkField";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const DestinationShow = (props) => {
  const t = useTranslate();

  return (
    <Show {...props} title={t("ra.destination.show_title")}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab label="Detalii">
          <BooleanField source="isPublished" label="Publicabil" />

          <MultilanguageTextField
            source="name"
            label={t("ra.destination.name")}
          />

          <MultilanguageTextField
            source="alternateName"
            label={t("ra.destination.alternate_name")}
          />
          <MultilanguageTextField
            source="description"
            label={t("ra.destination.description")}
          />

          <NumberField source="latitude" label={t("ra.general.latitude")} />

          <NumberField source="longitude" label={t("ra.general.longitude")} />
          <MultiLanguageLinkField
            source="pdf"
            label={t("ra.destination.pdf")}
          />
          <MultiLanguageLinkField
            source="transportation"
            label={t("ra.destination.transportation")}
          />
          <UrlField source="webcam" label={t("Webcam")} />
          <MultilanguageTextField
            source="longDescription"
            label={t("ra.destination.longDescription")}
          />
        </Tab>
        <Tab label={t("ra.destination.images")}>
          <ArrayField source="images">
            <Datagrid>
              <MultilanguageTextField
                source="alternateName"
                label={t("ra.general.image_alternate_name")}
                sortable={false}
              />

              <TextField
                source="author"
                label={t("ra.general.author")}
                sortable={false}
              />
              <ImageField
                source="resolutions[0].path"
                label={t("ra.general.image")}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label={t("ra.destination.videos")}>
          <ArrayField
            source="videos"
            label={t("ra.destination.videos")}
            fieldKey="url"
          >
            <Datagrid>
              <UrlField
                source="url"
                label={t("ra.destination.video_url")}
                sortable={false}
              />
              <TextField source="name.ro" label="Nume (RO)" sortable={false} />
              <TextField source="name.hu" label="Nume (HU)" sortable={false} />
              <TextField source="name.de" label="Nume (DE)" sortable={false} />
              <TextField source="name.en" label="Nume (EN)" sortable={false} />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

DestinationShow.propTypes = propTypes;
DestinationShow.defaultProps = defaultProps;

export default DestinationShow;
export {
  propTypes as DestinationShowPropTypes,
  defaultProps as DestinationShowDefaultProps,
};
