/**
 * Component short description
 *
 * @see PartnerForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { TextInput, SimpleForm, useTranslate, required } from "react-admin";

/**
 * Imports other components and hooks
 */
import EditableImageField from "../../EditableImageField";
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import { useValidation } from "../../../hooks";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./PartnerForm.data";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Imports Material UI components
 */

/**
 * Displays the component
 */
const PartnerForm = (props) => {
  const {
    validateTextGeneral,
    validateLink,
    validateResolutions,
    validateAlternateName,
  } = useValidation();
  const t = useTranslate();

  const validatePartnerLink = [required(), ...validateLink];
  return (
    <SimpleForm variant="outlined" {...props} redirect="show">
      <MultilanguageTextInput
        source="name"
        label={t("ra.partner.name")}
        fullWidth
        validate={validateTextGeneral}
      />
      <MultilanguageTextInput
        source="logo.alternateName"
        fullWidth
        label={t("ra.partner.logo_alternate_name")}
        validate={validateAlternateName}
      />
      <TextInputWithCharacterCount
        source="logo.author"
        label={t("ra.general.author")}
        fullWidth
        validate={validateTextGeneral}
      />
      <EditableImageField
        source="logo.resolutions"
        label={t("ra.partner.logo")}
        validate={validateResolutions}
      />
      <TextInput
        source="url"
        label={t("ra.general.url")}
        fullWidth
        validate={validatePartnerLink}
      />
    </SimpleForm>
  );
};

PartnerForm.propTypes = propTypes;
PartnerForm.defaultProps = defaultProps;

export default PartnerForm;
export {
  propTypes as PartnerFormPropTypes,
  defaultProps as PartnerFormDefaultProps,
};
