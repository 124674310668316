/**
 * Component short description
 *
 * @see FaqEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";
/**
 * Imports other components and hooks
 */
import FaqForm from "../FaqForm/FaqForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FaqEdit.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FaqEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit {...props} undoable={false} title={t("ra.faq.edit_title")}>
      <FaqForm />
    </Edit>
  );
};

FaqEdit.propTypes = propTypes;
FaqEdit.defaultProps = defaultProps;

export default FaqEdit;
export { propTypes as FaqEditPropTypes, defaultProps as FaqEditDefaultProps };
