import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tabError: {
    color: '#f44336',
  },
}));

const useTabsValidation = () => {
  const { tabError } = useStyles();

  const setTabsFormErrors = () => {
    const tabNode = document.querySelector('.MuiTabs-flexContainer');
    const tabbedForm = document.querySelector('.tabbed-form');
    const contentsNode = tabbedForm ? tabbedForm.childNodes[2] : null;
    const contents = contentsNode ? contentsNode.childNodes : [];
    const tabs = tabNode ? tabNode.childNodes : [];
    const tabIndices = {
      cleanIndices: [],
      errorIndices: [],
    };
    contents.forEach((content, index) => {
      const errors = content.getElementsByClassName('Mui-error');

      errors.length ? tabIndices.errorIndices.push(index) : tabIndices.cleanIndices.push(index);
    })

    Object.keys(tabIndices).forEach((tabIndex) => {
      tabIndices[tabIndex].forEach((index) => {
        const tab = tabs[index];
        tabIndex === 'errorIndices' ? tab.classList.add(tabError) : tab.classList.remove(tabError);
      })
    })
  }

  return { setTabsFormErrors }
}

export default useTabsValidation;
