const fileToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const parseImage = (image) => {
  if (!image) {
    return null;
  }

  const { resolutions } = image;
  const isUpdated =
    resolutions &&
    resolutions.some((res) => {
      return res.image;
    });

  const newImage = {
    ...image,
    edit: isUpdated,
  };

  if (!newImage.id) {
    delete newImage.id;
  }
  return newImage;
};

const destinations = [{ id: 1, name: "Borsec" }];
const categories = [{ id: 1, name: "Categorie" }];

const sanitizeRestProps = (props) => {
  const {
    allowEmpty,
    alwaysOn,
    basePath,
    component,
    defaultValue,
    formClassName,
    initialValue,
    initializeForm,
    input,
    isRequired,
    label,
    limitChoicesToValue,
    locale,
    meta,
    options,
    optionText,
    optionValue,
    record,
    resource,
    source,
    textAlign,
    translate,
    translateChoice,
    ...rest
  } = props;

  return rest;
};
export {
  fileToBase64,
  parseImage,
  destinations,
  categories,
  sanitizeRestProps,
};
