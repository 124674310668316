/**
 * Component short description
 *
 * @see BookingHistoryList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import ConditionalReferenceField from "../../ConditionalReferenceField/ConditionalReferenceField";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./BookingHistoryList.data";

/**
 * Imports Material UI components


/**
 * Styles the component
 */

/**
 * Displays the component
 */
const BookingHistoryList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      title={t("ra.booking_history.title")}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />
        <ConditionalReferenceField
          reference="stay/accommodations"
          source="accommodationId"
          label={t("ra.booking_history.accommodation")}
          sortable={false}
          link="show"
          referenceSource="name.ro"
          fallbackSource="accommodationName.ro"
        />
        <ReferenceField
          reference="destinations"
          source="destinationId"
          link="show"
          label={t("ra.booking_history.destination")}
          sortable={false}
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <TextField
          source="userName"
          label={t("ra.booking_history.username")}
          sortable={false}
        />
        <ReferenceField
          reference="stay/hosts"
          source="hostId"
          label={t("ra.booking_history.hostname")}
          sortable={false}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>

        <TextField
          source="price"
          label={t("ra.booking_history.price")}
          sortable={false}
        />
        <TextField
          source="checkIn"
          label={t("ra.booking_history.check_in")}
          sortable={false}
        />
        <TextField
          source="checkOut"
          label={t("ra.booking_history.check_out")}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

BookingHistoryList.propTypes = propTypes;
BookingHistoryList.defaultProps = defaultProps;

export default BookingHistoryList;
export {
  propTypes as BookingHistoryListPropTypes,
  defaultProps as BookingHistoryListDefaultProps,
};
