/**
 * Component short description
 *
 * @see MultilanguageTextField.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { TextField, Labeled } from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./MultilanguageTextField.data";

/**
 * Imports Material UI components
 */
import { Box } from "@material-ui/core";

/**
 * Displays the component
 */
const MultilanguageTextField = (props) => {
  const { source, label, sortable } = props;

  return (
    <Box className="ra-field" display="flex" flexDirection="column" style={{ wordBreak: "break-word" }}>
      <>
        <Labeled label={`${label} (RO)`}>
          <TextField
            {...props}
            source={`${source}.ro`}
            sortable={sortable}
            addLabel={false}
          />
        </Labeled>
        <Labeled label={`${label} (HU)`}>
          <TextField {...props} source={`${source}.hu`} sortable={sortable} />
        </Labeled>

        <Labeled label={`${label} (DE)`}>
          <TextField {...props} source={`${source}.de`} sortable={sortable} />
        </Labeled>

        <Labeled label={`${label} (EN)`}>
          <TextField {...props} source={`${source}.en`} sortable={sortable} />
        </Labeled>
      </>
    </Box>
  );
};

MultilanguageTextField.propTypes = propTypes;
MultilanguageTextField.defaultProps = defaultProps;

export default MultilanguageTextField;
export {
  propTypes as MultilanguageTextFieldPropTypes,
  defaultProps as MultilanguageTextFieldDefaultProps,
};
