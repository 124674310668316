/**
 * Component short description
 *
 * @see ProductList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  ReferenceField,
  BulkDeleteButton,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductList.data";

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const ProductList = (props) => {
  const t = useTranslate();
  const { permissions } = props;

  return (
    <List
      {...props}
      title={t("ra.product.title")}
      exporter={false}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false} />}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />
        <TextField
          label="ra.product.destination"
          source="destinationName.ro"
          sortable={false}
        />
        <ReferenceField
          sortable={false}
          reference="market/categories"
          source="productCategoryId"
          label={t("ra.product.category")}
          link={false}
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <ReferenceField
          reference="market/sellers"
          source="sellerId"
          label={t("ra.product.seller")}
          sortable={false}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField
          source="name.ro"
          label={t("ra.product.name")}
          sortable={false}
        />
        <TextField
          source="description.ro"
          label={t("ra.product.description")}
          sortable={false}
        />
        <TextField
          source="price"
          label={t("ra.product.price")}
          sortable={false}
        />
        {permissions?.isSeller && <EditButton basePath="/market/products" />}
      </Datagrid>
    </List>
  );
};

ProductList.propTypes = propTypes;
ProductList.defaultProps = defaultProps;

export default ProductList;
export {
  propTypes as ProductListPropTypes,
  defaultProps as ProductListDefaultProps,
};
