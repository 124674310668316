/**
 * Component short description
 *
 * @see PartnerEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */
import PartnerForm from "../PartnerForm/PartnerForm";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./PartnerEdit.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

const imageUpdated = (data) => {
  const resolutions = data?.logo?.resolutions;

  const isUpdated =
    resolutions && resolutions.length && resolutions[0].image ? true : false;

  return {
    ...data,
    logo: {
      ...data.logo,
      edit: isUpdated,
    },
  };
};

/**
 * Displays the component
 */
const PartnerEdit = (props) => {
  const t = useTranslate();
  return (
    <Edit
      {...props}
      undoable={false}
      title={t("ra.partner.edit_title")}
      transform={imageUpdated}
    >
      <PartnerForm />
    </Edit>
  );
};

PartnerEdit.propTypes = propTypes;
PartnerEdit.defaultProps = defaultProps;

export default PartnerEdit;
export {
  propTypes as PartnerEditPropTypes,
  defaultProps as PartnerEditDefaultProps,
};
