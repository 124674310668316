/**
 * Component short description
 *
 * @see ProductCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductCreate.data";
import { parseImage } from "../../ReactAdmin/ReactAdmin.logic";
import ProductForm from "../ProductForm/ProductForm";

const transformProduct = (data) => {
  const images = data?.images?.map((image) => {
    return parseImage(image);
  });

  return {
    ...data,
    images,
  };
};

/**
 * Displays the component
 */
const ProductCreate = (props) => {
  const t = useTranslate();

  return (
    <Create
      {...props}
      title={t("ra.product.create_title")}
      transform={transformProduct}
    >
      <ProductForm />
    </Create>
  );
};

ProductCreate.propTypes = propTypes;
ProductCreate.defaultProps = defaultProps;

export default ProductCreate;
export {
  transformProduct,
  propTypes as ProductCreatePropTypes,
  defaultProps as ProductCreateDefaultProps,
};
