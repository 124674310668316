/**
 * Component short description
 *
 * @see DropdownMultiSelect.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DropdownMultiSelect.data";
import {
  ReferenceArrayInput,
  SelectArrayInput,
  useTranslate,
} from "react-admin";

/**
 * Displays the component
 */
const DropdownMultiSelect = (props) => {
  const t = useTranslate();
  const { label, source, reference, validate, ...rest } = props;

  return (
    <ReferenceArrayInput
      {...rest}
      label={t(label)}
      source={source}
      reference={reference}
    >
      <SelectArrayInput
        optionText="name.ro"
        optionValue="id"
        validate={validate}
      />
    </ReferenceArrayInput>
  );
};

DropdownMultiSelect.propTypes = propTypes;
DropdownMultiSelect.defaultProps = defaultProps;

export default DropdownMultiSelect;
export {
  propTypes as DropdownMultiSelectPropTypes,
  defaultProps as DropdownMultiSelectDefaultProps,
};
