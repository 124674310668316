/**
 * Component short description
 *
 * @see SettingList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SettingList.data";
import BooleanField from "../../BooleanField/BooleanField";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const SettingList = (props) => {
  const t = useTranslate();

  return (
    <List
      {...props}
      title={t("ra.settings.title")}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />
        <BooleanField
          source="isNewsletterEnabled"
          label={t("ra.settings.newsletter")}
          sortable={false}
        />
        <TextField
          source="newsletterCounter"
          label={t("ra.settings.counter")}
          sortable={false}
        />
        <EditButton basePath="/settings" />
      </Datagrid>
    </List>
  );
};

SettingList.propTypes = propTypes;
SettingList.defaultProps = defaultProps;

export default SettingList;
export {
  propTypes as SettingListPropTypes,
  defaultProps as SettingListDefaultProps,
};
