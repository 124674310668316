/**
 * Component short description
 *
 * @see ProductEdit.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Edit, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductEdit.data";

/**
 * Imports Material UI components
 */
import { transformProduct } from "../ProductCreate/ProductCreate";
import ProductForm from "../ProductForm/ProductForm";

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const ProductEdit = (props) => {
  const t = useTranslate();

  return (
    <Edit
      {...props}
      title={t("ra.product.edit_title")}
      undoable={false}
      transform={transformProduct}
    >
      <ProductForm />
    </Edit>
  );
};

ProductEdit.propTypes = propTypes;
ProductEdit.defaultProps = defaultProps;

export default ProductEdit;
export {
  propTypes as ProductEditPropTypes,
  defaultProps as ProductEditDefaultProps,
};
