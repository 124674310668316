/**
 * Component short description
 *
 * @see DropdownSingleSelect.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { ReferenceInput, SelectInput, useTranslate } from "react-admin";
/**
 * Imports other components and hooks
 */
import { useValidation } from "../../hooks";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./DropdownSingleSelect.data";

/**
 * Displays the component
 */
const DropdownSingleSelect = props => {
  const t = useTranslate();
  const { validateDropdown } = useValidation();
  const { label, source, reference, ...rest } = props;

  return (
    <ReferenceInput
      {...rest}
      label={t(label)}
      source={source}
      reference={reference}
    >
      <SelectInput
        optionText="name.ro"
        optionValue="id"
        validate={validateDropdown}
      />
    </ReferenceInput>
  );
};

DropdownSingleSelect.propTypes = propTypes;
DropdownSingleSelect.defaultProps = defaultProps;

export default DropdownSingleSelect;
export {
  propTypes as DropdownSingleSelectPropTypes,
  defaultProps as DropdownSingleSelectDefaultProps
};
