/**
 * Component short description
 *
 * @see HostShow.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  Show,
  SimpleShowLayout,
  ArrayField,
  useTranslate,
  TextField,
  EmailField,
  Datagrid,
} from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HostShow.data";
import StatusField from "../../StatusField/StatusField";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HostShow = (props) => {
  const { permissions } = props;
  const t = useTranslate();
  return (
    <Show {...props} title={t("ra.host.show_title")}>
      <SimpleShowLayout>
        <TextField source="name" label={t("ra.host.name")} sortable={false} />
        <EmailField
          source="email"
          label={t("ra.host.email")}
          sortable={false}
        />
        <TextField source="phone" label={t("ra.host.phone")} sortable={false} />
        <ArrayField label="ra.host.approvals" source="approvals">
          <Datagrid
            rowClick={(id, basepath, record) => {
              return permissions?.isLocalAdmin || permissions?.isSuperAdmin
                ? `/stay/host-approvals/${record.id}`
                : null;
            }}
          >
            <TextField source="id" sortable={false} />
            <TextField
              source="destinationName"
              label={t("ra.host.destination")}
              sortable={false}
            />
            <StatusField
              source="status"
              label={t("ra.host.status")}
              sortable={false}
            />
            <TextField
              source="reason"
              label={t("ra.host.reason")}
              sortable={false}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

HostShow.propTypes = propTypes;
HostShow.defaultProps = defaultProps;

export default HostShow;
export { propTypes as HostShowPropTypes, defaultProps as HostShowDefaultProps };
