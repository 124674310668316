/**
 * Component short description
 *
 * @see AttractionList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  ReferenceField,
  BulkDeleteButton,
} from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionList.data";

/**
 * Imports Material UI components
 */

/**

/**
 * Displays the component
 */
const AttractionList = (props) => {
  const t = useTranslate();
  return (
    <List
      {...props}
      title={t("ra.attraction.title")}
      exporter={false}
      bulkActionButtons={<BulkDeleteButton {...props} undoable={false}/>}
    >
      <Datagrid {...props} rowClick="show">
        <TextField source="id" label="Id" sortable={false} />

        <TextField
          source="name.ro"
          label={t("ra.attraction.name")}
          sortable={false}
        />
        <ReferenceField
          reference="destinations"
          source="destinationId"
          label={t("ra.attraction.destination")}
          link="show"
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <ReferenceField
          link={null}
          reference="categories"
          source="categoryId"
          label={t("ra.attraction.category")}
        >
          <TextField source="name.ro" />
        </ReferenceField>
        <TextField
          source="description.ro"
          label={t("ra.attraction.description")}
          sortable={false}
        />
        <EditButton basePath="/attractions" />
      </Datagrid>
    </List>
  );
};

AttractionList.propTypes = propTypes;
AttractionList.defaultProps = defaultProps;

export default AttractionList;
export {
  propTypes as AttractionListPropTypes,
  defaultProps as AttractionListDefaultProps,
};
