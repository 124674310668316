/**
 * Component short description
 *
 * @see AccommodationForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import {
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useTranslate,
  TabbedFormTabs,
} from "react-admin";
/**
 * Imports other components and hooks
 */
import MultilanguageTextInput from "../../MultilanguageTextInput/MultilanguageTextInput";
import EditableImageField from "../../EditableImageField";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationForm.data";
import { useValidation } from "../../../hooks";
import DropdownSingleSelect from "../../DropdownSingleSelect/DropdownSingleSelect";
import DropdownMultiSelect from "../../DropdownMultiSelect/DropdownMultiSelect";
import useTabsValidation from "../../../hooks/useValidation/useTabsValidation";
import TextInputWithCharacterCount from "../../TextInputWithCharacterCount/TextInputWithCharacterCount";

/**
 * Displays the component
 */
const AccommodationForm = (props) => {
  const {
    validateTextGeneral,
    validateShortDescription,
    validatePriceWithMin,
    validateCarouselImages,
    validateResolutions,
    validateGps,
    validateDropdown,
    getDescriptionValidation
  } = useValidation();

  const t = useTranslate();
  const [toggleChange, setToggleChange] = useState(false);
  const { setTabsFormErrors } = useTabsValidation();

  useEffect(() => {
    setTabsFormErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleChange]);

  const handleValidate = () => {
    setToggleChange(!toggleChange);
  };

  return (
    <TabbedForm
      {...props}
      variant="outlined"
      onBlur={handleValidate}
      onClick={handleValidate}
      tabs={<TabbedFormTabs variant="scrollable" />}
    >
      <FormTab label={t("ra.accommodation.details")}>
        <DropdownSingleSelect
          label="ra.accommodation.destination"
          reference="destinations"
          source="destinationId"
          fullWidth
          validate={validateDropdown}
        />
        <DropdownSingleSelect
          label="ra.accommodation.type"
          source="accommodationTypeId"
          reference="stay/accommodation-types"
          fullWidth
          validate={validateDropdown}
        />
        <DropdownMultiSelect
          label="ra.accommodation.room_type"
          source="accommodationRoomTypeIds"
          reference="stay/room-types"
          fullWidth
          validate={validateDropdown}
        />
        <DropdownMultiSelect
          label="ra.accommodation.meal_type"
          source="accommodationMealIds"
          reference="stay/meals"
          fullWidth
          validate={validateDropdown}
        />
        <DropdownMultiSelect
          label="ra.accommodation.extras"
          source="accommodationExtrasIds"
          reference="stay/extras"
          fullWidth
          validate={validateDropdown}
        />
        <MultilanguageTextInput
          source="name"
          label={t("ra.accommodation.name")}
          fullWidth
          validate={validateTextGeneral}
        />
        <MultilanguageTextInput
          source="description"
          label={t("ra.accommodation.description")}
          fullWidth
          multiline
          validate={validateShortDescription}
        />
        <NumberInput
          source="price"
          label={t("ra.accommodation.price")}
          fullWidth
          min="0"
          variant="outlined"
          validate={validatePriceWithMin}
        />
        <NumberInput
          source="latitude"
          label={t("ra.general.latitude")}
          fullWidth
          variant="outlined"
          validate={validateGps}
        />
        <NumberInput
          source="longitude"
          label={t("ra.general.longitude")}
          fullWidth
          variant="outlined"
          validate={validateGps}
        />
      </FormTab>
      <FormTab label={t("ra.accommodation.images")}>
        <ArrayInput source="images" validate={validateCarouselImages}>
          <SimpleFormIterator>
            <TextInputWithCharacterCount
              source="author"
              label={t("ra.general.author")}
              fullWidth
              validate={validateTextGeneral}
            />
            <EditableImageField
              source="resolutions"
              label={t("ra.general.image")}
              validate={validateResolutions}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label={t("ra.general.general_description")}>
        <MultilanguageTextInput
          source="sectionDescription1"
          label={t("ra.general.description")}
          validate={getDescriptionValidation(500, 10000)}
          multiline
          fullWidth
        />
      </FormTab>
    </TabbedForm>
  );
};

AccommodationForm.propTypes = propTypes;
AccommodationForm.defaultProps = defaultProps;

export default AccommodationForm;
export {
  propTypes as AccommodationFormPropTypes,
  defaultProps as AccommodationFormDefaultProps,
};
