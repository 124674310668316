/**
 * Defines the data requirements for the component
 *
 * @see MultiLanguageFileInput.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {};

export { propTypes, defaultProps };
