/**
 * Component short description
 *
 * @see ToolbarWithoutDelete.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Toolbar, SaveButton } from "react-admin";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ToolbarWithoutDelete.data";

/**
 * Imports Material UI components
 */

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const ToolbarWithoutDelete = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

ToolbarWithoutDelete.propTypeπtrs = propTypes;
ToolbarWithoutDelete.defaultProps = defaultProps;

export default ToolbarWithoutDelete;
export {
  propTypes as ToolbarWithoutDeletePropTypes,
  defaultProps as ToolbarWithoutDeleteDefaultProps,
};
