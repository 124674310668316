/**
 * Component short description
 *
 * @see NewsCreate.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Create, useTranslate } from "react-admin";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./NewsCreate.data";

/**
 * Imports Material UI components
 */
import NewsForm from "../NewsForm/NewsForm";
import { parseImage } from "../../ReactAdmin/ReactAdmin.logic";

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const transformNews = (data) => {
  const image = parseImage(data.image);
  const image1 = parseImage(data.image1);
  const image2 = parseImage(data.image2);

  return {
    ...data,
    image,
    image1,
    image2,
  };
};

const NewsCreate = (props) => {
  const t = useTranslate();
  return (
    <Create
      title={t("ra.news.create_title")}
      transform={transformNews}
      {...props}
    >
      <NewsForm />
    </Create>
  );
};

NewsCreate.propTypes = propTypes;
NewsCreate.defaultProps = defaultProps;

export default NewsCreate;
export {
  transformNews,
  propTypes as NewsCreatePropTypes,
  defaultProps as NewsCreateDefaultProps,
};
